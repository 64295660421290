import type { Resource } from '@app/@types/redux/project';
import { Col } from '@components/UI/Html';
import { type SlideOverData } from '@components/UI/Html/SlideOver';

import type { Dispatch, SetStateAction } from 'react';
import resourcePng from '../../assets/images/placeholders/resource.png';
import './Resource.scss';
import { FaFilePdf, FaFileWord, FaFileImage, FaFileVideo } from 'react-icons/fa'; // Import icons
import { MdAudiotrack } from 'react-icons/md';
import { FaFileCsv } from 'react-icons/fa6';

interface ResourceSlideOverProps {
  slideOverData: SlideOverData;
  setSlideOverData: Dispatch<SetStateAction<SlideOverData>>;
  resources: boolean;
  challengeResources: Resource[];
  workspaceResources: Resource[];
}
const Resources = ({
  resources,
  challengeResources,
  workspaceResources,
}: ResourceSlideOverProps) => {
  return (
    <div>
      {resources ? (
        <div>
          <>
            {challengeResources.length === 0 && workspaceResources.length === 0 ? (
              <Col className="text-center" sm={12} md={12}>
                No resources found
              </Col>
            ) : (
              <>
                <div>
                  {challengeResources.map((resource) => {
                    return <Resource key={resource.url} resource={resource} />;
                  })}
                  {workspaceResources.map((resource) => {
                    return <Resource key={resource.url} resource={resource} />;
                  })}
                </div>
              </>
            )}
          </>
        </div>
      ) : (
        <div>
          <p>No resources available</p>
        </div>
      )}
    </div>
  );
};
export default Resources;

interface ResourceProps {
  resource: Resource;
}
function Resource({ resource }: ResourceProps) {
  const getIcon = (url: string) => {
    const extension = url.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return <FaFilePdf className="resource-icon pdf" />;
      case 'doc':
      case 'docx':
        return <FaFileWord className="resource-icon doc" />;
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <FaFileImage className="resource-icon image" />;
      case 'wav':
      case 'mp3':
        return <MdAudiotrack className="resource-icon music" />;
      case 'mp4':
      case 'avi':
      case 'mov':
        return <FaFileVideo className="resource-icon video" />;
      case 'csv':
        return <FaFileCsv className="resource-icon csv" />;
      default:
        return <img alt="..." src={resourcePng} className="resource-icon" />;
    }
  };

  return (
    <div className="resource-container">
      {getIcon(resource.url)}
      <a href={resource.url} target="_blank" rel="noreferrer" className="resource-title">
        {resource.name}
      </a>
    </div>
  );
}
