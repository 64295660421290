import Routes from '@app/routes/routes';
import env from '@config/env';
import { StylesProvider, ThemeProvider, createTheme, jssPreset } from '@material-ui/core/styles';
import { Provider as RollbarProvider } from '@rollbar/react';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import { create } from 'jss';
import rtl from 'jss-rtl';
import React from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import 'react-notifications-component/dist/theme.css';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import metaImage from '../assets/images/meta_image.jpg';
import { TITLE } from '../libs/constants/constants';
import i18n from '../libs/i18n/I18n';
import { store } from '../state/redux/store';
import { translate } from '../state/utils/helper';
import Loading from './Loading';
import { Worker } from '@react-pdf-viewer/core';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  palette: {
    primary: {
      main: '#2caee4',
    },
    secondary: {
      main: '#2caee4',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white',
        fontWeight: 600,
      },
    },
  },
  direction: i18n.dir(),
});

const rollbarConfig = {
  accessToken: env.ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: env.PROD,
  payload: {
    environment: env.ROLLBAR_ENV,
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

const MainApp: React.FC = () => {
  ReactGA.initialize(env.GA_TRACKING_ID);
  const persistor = persistStore(store);
  return (
    <div>
      <RollbarProvider config={rollbarConfig}>
        <Provider store={store}>
          <I18nextProvider i18n={i18n} defaultNS="df">
            <StylesProvider jss={jss}>
              <ThemeProvider theme={theme}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <PersistGate loading={<Loading />} persistor={persistor}>
                    <Helmet>
                      <title>{TITLE}</title>
                      <meta
                        name="description"
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        content={translate('meta.description')}
                      />
                      <meta name="title" content={TITLE} />
                      <meta property="og:type" content="website" />
                      <meta property="og:url" content={env.APP_URL} />
                      <meta property="og:title" content={TITLE} />
                      <meta property="og:description" content={translate('meta.description')} />
                      <meta property="og:image" content={metaImage} />
                      <meta property="twitter:card" content="summary_large_image" />
                      <meta property="twitter:url" content={env.APP_URL} />
                      <meta property="twitter:title" content={TITLE} />
                      <meta
                        property="twitter:description"
                        content={translate('meta.description')}
                      />
                      <meta property="twitter:image" content={metaImage} />
                    </Helmet>
                    <Routes />
                  </PersistGate>
                </Worker>
              </ThemeProvider>
            </StylesProvider>
          </I18nextProvider>
        </Provider>
      </RollbarProvider>
    </div>
  );
};

export default MainApp;
