import { redirectToExternal } from '@app/routes/helpers';
import env from '@config/env';
import i18n from '@libs/i18n/I18n';
import { useRollbar } from '@rollbar/react';
import { logout } from '@state/redux/store';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { error } from '../../../assets/images';
import { translate } from '../../../state/utils/helper';
import NavBar from '../NavigationBar/NavBar';
import ErrorPage from './ErrorPage';
import LayoutBody from './LayoutBody';

const ErrorBoundary = (props: { error?: number }) => {
  const errorFromRouter = useRouteError();
  const rollbar = useRollbar();

  useEffect(() => {
    if (errorFromRouter) {
      rollbar.error(errorFromRouter);
      if (env.PROD) {
        setTimeout(() => {
          logout(); // TEMPORARY: Logout user if error is from router and in production
          redirectToExternal('/', '_self');
        }, 2000);
      }
    }
  }, [errorFromRouter]);

  const errorCode = props.error === 404 ? props.error : 500;

  return (
    <>
      <NavBar language={i18n.language} />
      <LayoutBody>
        <ErrorPage
          errorMessage={translate('errors.error404Message')}
          errorImage={error[errorCode]}
        />
        ;
      </LayoutBody>
    </>
  );
};

export default ErrorBoundary;
