import classnames from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';

interface ModalProps {
  children?: ReactNode;
  style?: {
    content?: CSSProperties;
    overlay?: CSSProperties;
    zIndex?: number;
  };
  isOpen?: boolean | string;
  toggle?: () => void;
  showCloseButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  blur?: boolean;
  size?: string;
  className?: string;
  width?: string;
}

const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    top: '',
    left: '',
    right: '',
    bottom: '',
    border: '',
    borderRadius: '',
    padding: '2em',
  },
};

const Modal: FC<ModalProps> = ({
  children,
  style,
  isOpen = false,
  toggle = undefined,
  showCloseButton = true,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  blur = true,
  size = 'lg',
  className = '',
  width = '',
}) => {
  const maxWidth = {
    sm: 380,
    md: 500,
    lg: 992,
  };
  return (
    <div>
      <ReactModal
        isOpen={Boolean(isOpen)}
        onRequestClose={toggle}
        style={{
          ...customStyles,
          content: {
            ...customStyles.content,
            ...style?.content,
            width: width || `${maxWidth[size as keyof typeof maxWidth].toString()}px`,
          },
          overlay: {
            ...customStyles.overlay,
            ...style?.overlay,
          },
        }}
        contentLabel="Modal"
        closeTimeoutMS={100}
        ariaHideApp={false}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        className={classnames('custom-modal', className)}
        overlayClassName="custom-modal-overlay"
        portalClassName="custom-modal"
        bodyOpenClassName={classnames({
          'custom-modal-blur-body-open': blur,
        })}
      >
        {showCloseButton && (
          <button
            type="button"
            className="custom-modal-close"
            aria-label="Close this dialog"
            onClick={toggle}
          >
            ×
          </button>
        )}
        {children}
      </ReactModal>
    </div>
  );
};

export default Modal;
