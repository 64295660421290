import { Button, Modal } from '@components/UI/Html';
import classNames from 'classnames';
import React from 'react';
import type { IconType } from 'react-icons/lib';
import './NotificationModal.scss';

interface NotificationModalProps {
  isOpen: boolean;
  toggle: () => void;
  onViewMessage: () => void;
  icon?: string | IconType;
  imageSrc?: string;
  title: string;
  message: string;
  buttonIcon?: string;
  buttonText?: string;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  isOpen,
  toggle,
  onViewMessage,
  icon,
  title,
  message,
  buttonIcon,
  buttonText,
  imageSrc,
}) => {
  const [pickupInitiated, setPickupInitiated] = React.useState(false);
  function handlePickup() {
    setPickupInitiated(true);
    setTimeout(() => {
      toggle();
      onViewMessage();
    }, 900);
  }
  const notificationAudio =
    'https://cartedo.s3.us-east-2.amazonaws.com/web/04c8cf25969b02577edd.mp3';
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      className="custom__modal__gray"
      showCloseButton={false}
    >
      {/* Add ping audio notification alert */}
      <audio src={notificationAudio} autoPlay style={{ display: 'none' }} />
      <div className="nf-container">
        <div className="nf-icon-container">
          {imageSrc ? (
            <img className="nf-image-profile" src={imageSrc} alt="icon" />
          ) : typeof icon === 'string' ? (
            <img className="nf-image" src={icon} alt="icon" />
          ) : (
            <div className="nf-icon">{icon && React.createElement(icon)}</div>
          )}
          <div className="nf-badge">1</div>
        </div>

        <h2 className="nf-heading">{title}</h2>
        <p className="nf-text">{message}</p>
        {buttonIcon && buttonText ? (
          <div
            className={classNames('nf-button-container', {
              'nf-button-container--pickup': pickupInitiated,
            })}
            onClick={handlePickup}
          >
            <div
              className={classNames('nf-button-icon-container', {
                'nf-button-icon-container--rotate': !pickupInitiated,
              })}
            >
              <img
                className={classNames(
                  'nf-button-icon',
                  pickupInitiated ? 'nf-button-icon--rotate' : ''
                )}
                src={buttonIcon}
                alt="icon"
              />
            </div>
            <div className="nf-button-text">{buttonText}</div>
          </div>
        ) : (
          <Button
            onClick={() => {
              toggle();
              onViewMessage();
            }}
            className="btn btn-primary mt-4"
          >
            View Message
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default NotificationModal;
