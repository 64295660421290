import { OthersOptions } from '@app/@types/redux/pageElement';
import { PageElement } from '@app/@types/redux/project';
import { redirectToExternal } from '@app/routes/helpers';
import { useAppSelector } from '@state/redux/store';
import { Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import resourcePng from '../../../../../assets/images/placeholders/resource.png';
import { i18nCText } from '../../../../../libs/i18n/I18n';
import NoStyleDiv from '../../../../UI/Html/NoStyleDiv';

const ResourcesPage = (props: { pageElement: PageElement }) => {
  const { pageElement } = props;
  const activityResources = (pageElement.attributes.options as OthersOptions).resources;
  const projectResources = useAppSelector(
    (state) => state.projects.currentProject.project.attributes.options.resources
  );
  const workspaceResources = useAppSelector(
    (state) => state.workspaces.currentWorkspace.attributes.options.resources
  );
  return (
    <div>
      <Row className="my-4">
        {activityResources.length > 0 ? (
          <>
            {activityResources.map((resourceP) => {
              return resourceP.resources.map((resource, index) => {
                return (
                  <ResourceBlock
                    key={index}
                    name={resourceP.name || resource.name}
                    url={resource.url}
                    description={resourceP.description}
                  />
                );
              });
            })}
          </>
        ) : null}
        {projectResources.length > 0 ? (
          <>
            {projectResources.map((resource, index) => {
              return <ResourceBlock key={index} name={resource.name} url={resource.url} />;
            })}
          </>
        ) : null}
        {workspaceResources && workspaceResources.length > 0 ? (
          <>
            {workspaceResources.map((resource, index) => {
              return <ResourceBlock key={index} name={resource.name} url={resource.url} />;
            })}
          </>
        ) : null}
      </Row>
    </div>
  );
};

function ResourceBlock(props: { url: string; name: string; description?: string }) {
  const { url, name } = props;
  function Image() {
    return <CardImg alt="..." src={resourcePng} top />;
  }
  return (
    <Col key={url} className="" sm={12} md={3}>
      <Card>
        {Image()}
        <CardBody>
          <CardTitle className="font-weight-bold">{i18nCText(name)}</CardTitle>
          <CardText>
            {props.description && (
              <div className="mb-2">
                <NoStyleDiv>{props.description}</NoStyleDiv>
              </div>
            )}
          </CardText>
          <CardText>
            <Button
              color="link"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                redirectToExternal(url);
              }}
            >
              View
            </Button>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
}

export default ResourcesPage;
