import { redirectTo } from '@app/routes/helpers';
import env from '@config/env';
import logger from '@libs/log';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Api, purgeHeaders } from '@state/utils/Api';
import { getRefreshToken } from '@state/utils/helper';
import { AxiosResponse, type AxiosError, type AxiosRequestConfig } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer, { logoutUser, refresh, updateLocation } from './slices/auth.slice';
import projectsReducer from './slices/project.slice';
import usersReducer from './slices/user.slice';
import workspaceReducer from './slices/workspace.slice';

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
};

const reducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  workspaces: workspaceReducer,
  projects: projectsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
    return middlewares;
  },
  devTools: env.DEV || env.ROLLBAR_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export const useAppSelector = useSelector.withTypes<RootState>();

export const persistor = persistStore(store);

export const logout = () => {
  // logout user action in auth slice
  store.dispatch(logoutUser());

  // clear update location action in auth slice
  store.dispatch(
    updateLocation({
      pathname: '/',
      search: '',
      hash: '',
      key: '',
    })
  );

  // clear local storage
  localStorage.clear();

  // clear token headers
  purgeHeaders();

  // clear redux store
  persistor.purge().catch(() => {
    logger.error('Failed to purge the store');
  });

  // redirect to sign in page
  redirectTo('/sign_in');
};

Api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.data &&
      (response.headers['content-type'] as string).includes('application/json')
    ) {
      if (typeof response.data === 'object' && response.data !== null) {
        response.data = camelcaseKeys(response.data as Record<string, unknown>, { deep: true });
      }
    }
    return response;
  },
  (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig;
    if (error.response?.status === 401 && originalRequest.url === '/oauth/token') {
      logout();
      window.location.reload();
      return Promise.reject(new Error(String(error)));
    }
    if (error.response?.status === 401 && getRefreshToken()) {
      store
        .dispatch(refresh({ refreshToken: getRefreshToken() ?? '' }))
        .then(() => {
          return Api(originalRequest);
        })
        .catch(() => {
          logout();
          window.location.reload();
          return Promise.reject(new Error(String(error)));
        });
    }
    return Promise.reject(new Error(String(error)));
  }
);
