import { VideoOptions } from '@app/@types/redux/pageElement';

import type { ChatActivityAnswer } from '@app/@types/redux/answer';
import TempChat, { type ChatItem } from '@components/Chat/TempChat';
import { Modal } from '@components/UI/Html';
import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '@state/redux/store';
import { useEffect, useState } from 'react';
import { GoDotFill } from 'react-icons/go';
import ReactPlayer from 'react-player';
import { PageElementProps } from '..';
import videoIcon from '../../../../assets/icons/videoIcon.png';
import NotificationModal from './NotificationModal';
import './Video.scss';
const Video: React.FC<PageElementProps> = (props) => {
  const { pageElement, currentAnswer, answer } = props;
  const { value } = pageElement.attributes.options as VideoOptions;
  const [videoModal, setVideoModal] = useState(false);
  const [videoTutorialModal, setVideoTutorialModal] = useState(false);

  useEffect(() => {
    if (currentAnswer && !(currentAnswer as ChatActivityAnswer).videoFlag) {
      setVideoModal(true);
    }
  }, [currentAnswer]);

  const topicWizardData = useAppSelector(
    (state) =>
      state.projects.currentProject.project.attributes.options.creationOptions.topicWizardData
  );
  const tempMessages: ChatItem[] = [
    {
      id: nanoid(),
      message: value,
      sender: 'assistant',
      timestamp: new Date().toISOString(),
      openedAt: null,
    },
  ];
  return (
    <>
      <TempChat
        pageElement={pageElement}
        currentAnswer={currentAnswer}
        answer={props.answer}
        getDependeeAnswerType={props.getDependeeAnswerType}
        state={props.state}
        tempMessages={tempMessages}
      />
      <NotificationModal
        isOpen={videoModal}
        toggle={() => {
          setVideoModal(false);
        }}
        imageSrc={
          topicWizardData.workplaceScenario?.learnerRoleReportingManager.reportingManager
            .avatarUrl ?? 'https://randomuser.me/api/portraits/women/1.jpg'
        }
        title="Recorded Video Message"
        message={`1 new message ${
          topicWizardData.workplaceScenario?.learnerRoleReportingManager.reportingManager.name
            ? `from ${
                topicWizardData.workplaceScenario.learnerRoleReportingManager.reportingManager
                  .name || ''
              }`
            : ''
        }`}
        onViewMessage={() => {
          setVideoModal(false);
          answer({
            ...(currentAnswer as ChatActivityAnswer),
            videoFlag: true,
          });
          setVideoTutorialModal(true);
        }}
        buttonIcon="https://cartedo.s3.us-east-2.amazonaws.com/web/7fca80c527573e84acc0.png"
        buttonText="Click to watch video"
      />

      <Modal
        size="lg"
        isOpen={videoTutorialModal}
        toggle={() => {
          setVideoTutorialModal(false);
        }}
        className=""
        showCloseButton={false}
      >
        <div className="vd_video_container">
          <div className="vd_video_subtitle_container">
            <img className="vd_video_subtitle_image" src={videoIcon} alt="video icon" />
            <div className="chatbox_video_subtitle_text_container">
              <div className="chatbox_video_subtitle_text">Video Message</div>
              <div className="chatbox_video_subtitle_subtext">
                <div className="chatbox_video_subtitle_recorded_container">
                  <GoDotFill color="#cbcbcb" size={20} />
                  <div className="sdf">RECORDED</div>
                </div>
              </div>
            </div>
          </div>
          <ReactPlayer
            url={value}
            volume={0.8}
            muted={false}
            playing={false}
            controls
            width="100%"
            height={500}
            config={{
              vimeo: {
                playerOptions: {
                  title: true,
                  byline: false,
                  portrait: false,
                  vimeoLogo: false,
                },
              },
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default Video;
