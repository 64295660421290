import './ThreeDButton.scss';

const ThreeDButton = (props: {
  colorStyle?: 'default' | 'email' | 'chat' | 'notes' | 'tedo' | 'menu';
  children: React.ReactNode;
  id: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  badgeCount?: number;
}) => {
  const { colorStyle = 'default' } = props; // default, blue, vio
  let firstColor = '';
  let secondColor = '';
  switch (colorStyle) {
    case 'email':
      firstColor = '16,201,238';
      secondColor = '16,124,238';
      break;
    case 'chat':
      firstColor = '246, 57, 90';
      secondColor = '255, 145, 77';
      break;
    case 'notes':
      firstColor = '255, 222, 89';
      secondColor = '255, 145, 77';
      break;
    case 'tedo':
      firstColor = '60, 106, 211';
      secondColor = '93, 58, 212';
      break;
    case 'menu':
      firstColor = '255, 255, 255';
      secondColor = '255, 255, 255';
      break;
    default:
      firstColor = '0,0,0';
      secondColor = '0,0,0';
      break;
  }
  return (
    <button className="btn-3d-pushable" id={props.id} onClick={props.onClick}>
      <span className="btn-3d-shadow"></span>
      <span
        className="btn-3d-edge"
        style={{
          background: `linear-gradient(to left,rgba(${secondColor}, 0.5) 0%,rgba(${secondColor}, 0.7) 8%,rgba(${firstColor}, 0.7) 92%,rgba(${firstColor}, 0.5) 100%)`,
        }}
      ></span>
      <span
        className="btn-3d-front"
        style={{
          background: `linear-gradient(90deg, rgba(${firstColor},1) 0%, rgba(${secondColor},0.97) 100%)`,
        }}
      >
        {props.children}
        {props.badgeCount && props.badgeCount > 0 ? (
          <span className="three-d-button-badge">{props.badgeCount}</span>
        ) : null}
      </span>
    </button>
  );
};

export default ThreeDButton;
