import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState, useAppDispatch, useAppSelector } from '@state/redux/store';
import { formatErrorCode } from '@state/utils/helper';
import { isLoggedIn } from '@utils/helpers/auth';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ZodType, z } from 'zod';
import { EMAIL_SUFFIX } from '../../../libs/constants/constants';
import { errorToast, redirectToPrevious, strengthIndicator } from '../../../libs/helper';
import i18n from '../../../libs/i18n/I18n';
import { redirectTo } from '../../../routes/helpers';
import { loginAfterSignUp, signUp } from '../../../state/redux/slices/auth.slice';
import {
  A,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  RHFInput as Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  SubmitButton,
} from '../../UI/Html';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';
import Header from './Header';

export interface SignUpForm {
  email: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
}

function SignUp() {
  redirectTo('/sign_in');
  const isSignedIn = isLoggedIn();
  const previousLocation = useAppSelector((state: RootState) => state.auth.previousLocation);
  if (isSignedIn) {
    redirectTo('/');
  }
  const dispatch = useAppDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [focusEmailInput, setFocusEmailInput] = useState(false);
  const [focusPasswordInput, setFocusPasswordInput] = useState(false);
  const [focusPasswordConfirmInput, setFocusPasswordConfirmInput] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const validationSchema: ZodType<SignUpForm> = z.object({
    email: z.string().email(),
    password: z.string().min(6),
    confirmPassword: z.string().min(6),
    termsAndConditions: z.boolean(),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignUpForm>({
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
  });

  const password = watch('password') || '';
  useEffect(() => {
    setPasswordStrength(strengthIndicator(password));
  }, [password]);
  useEffect(() => {
    document.body.classList.add('bg-default-color');
    return () => {
      document.body.classList.remove('bg-default-color');
    };
  }, []);
  const onSubmit = (data: { email: string; password: string; confirmPassword: string }) => {
    setSubmitLoading(true);
    const { email: formEmail, password: formPassword, confirmPassword } = data;
    const email = formEmail.toLowerCase().includes('@cartedo.com')
      ? formEmail.toLowerCase()
      : `${formEmail.toLowerCase()}${EMAIL_SUFFIX}`;
    dispatch(
      signUp({
        email,
        password: formPassword,
        confirmPassword,
      })
    )
      .unwrap()
      .then((response) => {
        dispatch(
          loginAfterSignUp({
            refreshToken: response.refreshToken,
          })
        )
          .then(() => {
            // if (res.error) {
            //   errorToast(i18n.t('errors.invalidLogin'));
            //   return;
            // }
            setSubmitLoading(false);
            redirectToPrevious(previousLocation);
          })
          .catch((e: unknown) => {
            setSubmitLoading(false);
            errorToast(`Error registering in ${String(e)}`);
          });
      })
      .catch(() => {
        setSubmitLoading(false);
        errorToast(formatErrorCode('ERCTLNR-CIGBF7F'));
      });
  };
  const isRTL = i18n.dir() === 'rtl';
  const { ref: refEmail, ...registerEmail } = register('email');
  const { ref: refPassword, ...registerPassword } = register('password');
  const { ref: refConfirmPassword, ...registerConfirmPassword } = register('confirmPassword');
  const { ref: refTermsAndConditions, ...registerTermsAndConditions } =
    register('termsAndConditions');

  return (
    <Layout isFullWidth bg>
      <Header title={i18n.t('auth.signUp.heading')} lead={i18n.t('auth.signUp.subheading')} />
      <Container className="mt--8 pb-5 main-container-sign-out">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <FormGroup
                    className={classnames([
                      {
                        focused: focusEmailInput,
                      },
                      errors.email ? 'mb-1' : 'mb-4',
                    ])}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      {!isRTL && (
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                      )}
                      <Input
                        placeholder={i18n.t('auth.signInEmail')}
                        type="text"
                        id="email"
                        onFocus={() => {
                          setFocusEmailInput(true);
                        }}
                        innerRef={refEmail}
                        required
                        autoComplete="email"
                        dir={i18n.dir()}
                        lang={i18n.language}
                        {...registerEmail}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="tamu-email-extension">
                          {EMAIL_SUFFIX}
                        </InputGroupText>
                      </InputGroupAddon>
                      {isRTL && (
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                      )}
                    </InputGroup>
                  </FormGroup>
                  <div className="text-muted mb-3 pl-2">
                    <small>
                      <span className="text-danger">
                        <ErrorMessage errors={errors} name="email" />
                      </span>
                    </small>
                  </div>
                  <FormGroup
                    className={classnames('mb-1', {
                      focused: focusPasswordInput,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      {!isRTL && (
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                      )}
                      <Input
                        placeholder={i18n.t('auth.signInPassword')}
                        type="password"
                        id="password"
                        onFocus={() => {
                          setFocusPasswordInput(true);
                        }}
                        innerRef={refPassword}
                        autoComplete="new-password"
                        required
                        dir={i18n.dir()}
                        lang={i18n.language}
                        {...registerPassword}
                      />
                      {isRTL && (
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                      )}
                    </InputGroup>
                  </FormGroup>
                  <div
                    className={classnames('text-muted font-italic mb-3 pl-1', {
                      'text-right': isRTL,
                    })}
                    dir={i18n.dir()}
                  >
                    {errors.password ? (
                      <small>
                        <span className="text-danger">
                          <ErrorMessage errors={errors} name="password" />
                        </span>
                      </small>
                    ) : (
                      <small>
                        {`${i18n.t('security.passwordStrength')}: `}
                        <span
                          className={classnames({
                            'font-weight-700': true,
                            'text-danger': passwordStrength <= 1,
                            'text-warning': passwordStrength > 1 && passwordStrength < 4,
                            'text-success': passwordStrength > 3,
                          })}
                        >
                          {passwordStrength <= 1 && String(i18n.t('security.passwordStrengthWeak'))}
                          {passwordStrength > 1 &&
                            passwordStrength < 4 &&
                            String(i18n.t('security.passwordStrengthMedium'))}
                          {passwordStrength > 3 &&
                            String(i18n.t('security.passwordStrengthStrong'))}
                        </span>
                      </small>
                    )}
                  </div>
                  <FormGroup
                    className={classnames([
                      {
                        focused: focusPasswordConfirmInput,
                      },
                      errors.confirmPassword ? 'mb-1' : 'mb-4',
                    ])}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      {!isRTL && (
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                      )}
                      <Input
                        placeholder={i18n.t('auth.signUpConfirmPassword')}
                        type="password"
                        id="confirmPassword"
                        onFocus={() => {
                          setFocusPasswordConfirmInput(true);
                        }}
                        innerRef={refConfirmPassword}
                        required
                        autoComplete="confirmPassword"
                        dir={i18n.dir()}
                        lang={i18n.language}
                        {...registerConfirmPassword}
                      />
                      {isRTL && (
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                      )}
                    </InputGroup>
                  </FormGroup>
                  <div className="text-muted mb-2 pl-2">
                    <small>
                      <span className="text-danger">
                        <ErrorMessage errors={errors} name="confirmPassword" />
                      </span>
                    </small>
                  </div>
                  <Row className="mt-4 mb-3">
                    <Col xs="12">
                      <div
                        className="custom-control custom-control-alternative custom-checkbox"
                        dir={i18n.dir()}
                      >
                        <Input
                          className={classnames([
                            'custom-control-input',
                            {
                              'is-invalid': errors.termsAndConditions,
                            },
                          ])}
                          id="termsAndConditions"
                          type="checkbox"
                          innerRef={refTermsAndConditions}
                          {...registerTermsAndConditions}
                        />
                        <label className="custom-control-label" htmlFor="termsAndConditions">
                          <span
                            className={classnames(
                              errors.termsAndConditions &&
                                errors.termsAndConditions.type === 'required'
                                ? 'text-danger'
                                : 'text-muted'
                            )}
                          >
                            {`${i18n.t('auth.signUp.over14AcceptTnC')} `}
                            <A
                              href="/terms-and-conditions"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {String(i18n.t('auth.signUp.termsAndConditions'))}
                            </A>
                            .
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <SubmitButton
                      className="mt-4 btn-round btn-block"
                      color="info"
                      loading={submitLoading}
                      type="submit"
                    >
                      {String(i18n.t('auth.signUp.createAccountBtn'))}
                    </SubmitButton>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <A className="text-light" href="/forgot_password">
                  <small>{String(i18n.t('auth.forgotPassword.heading'))}</small>
                </A>
              </Col>
              <Col className="text-right" xs="6">
                <A className="text-light" href="/sign_in">
                  <small>{String(i18n.t('auth.alreadyAccount'))}</small>
                </A>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Footer />
      </Container>
    </Layout>
  );
}

export default SignUp;
