import { useAppSelector } from '@state/redux/store';
import moment from 'moment';
import { FiGrid } from 'react-icons/fi';
import graphImage from '../../../assets/images/placeholders/graph.png';
import { translate } from '../../../state/utils/helper';

import { redirectToExternal } from '@app/routes/helpers';
import env from '@config/env';
import { Button, Col, H1, H4, Row } from '../../UI/Html';

function ChallengeReport({ reportId }: { reportId: string }) {
  const url = `${env.TEACHER_PLATFORM_URL}/challenge_report/${reportId}`;

  const scheduleData = useAppSelector(
    (state) => state.projects.currentProject.project.attributes.showReportDate
  );

  return (
    <Row>
      <Col xs={{ size: 12, order: 2 }} md={{ size: 4, order: 1 }} className="text-center">
        <img src={graphImage} style={{ height: 200 }} />
      </Col>
      <Col xs={{ size: 12, order: 1 }} md={{ size: 8, order: 2 }} className="mb-4">
        <Row>
          <Col>
            <H1>{translate('report.challengeReport.title')}</H1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{translate('report.challengeReport.description')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            {scheduleData && new Date(scheduleData) > new Date() ? (
              <H4 className="report-schedule">
                {`Your report will be available on ${moment(scheduleData).format(
                  'MMM DD YYYY'
                )}. Please check back then.`}
              </H4>
            ) : (
              <Button
                className="btn-icon btn-3"
                color="info"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  redirectToExternal(url, '_blank');
                }}
              >
                <span className="btn-inner--icon">
                  <FiGrid size={20} />
                </span>
                <span className="btn-inner--text">
                  {translate('report.challengeReport.reportButton')}
                </span>
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export { ChallengeReport };
