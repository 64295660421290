import logger from '@libs/log';
import { fetchSimulationEmails } from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { useEffect, type Dispatch, type SetStateAction } from 'react';
import { Modal } from '@components/UI/Html';
import EmailMessenger from '.';

interface EmailModalProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}
function EmailModal({ modal, setModal }: EmailModalProps) {
  const toggle = () => {
    setModal(!modal);
  };

  const userProjectEmails = useAppSelector((state) => state.projects.currentProject.emails);
  const projectId = useAppSelector((state) => state.projects.currentProject.project.id);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchSimulationEmails({ projectId })).catch(() => {
      logger.error('Failed to fetch simulation emails');
    });
  }, []);
  return (
    <Modal
      size="lg"
      isOpen={modal}
      toggle={toggle}
      showCloseButton={false}
      style={{ content: { padding: '0px 24px 24px' } }}
      // external={externalCloseBtn}
    >
      <EmailMessenger onClose={toggle} emails={userProjectEmails} />
    </Modal>
  );
}

export default EmailModal;
