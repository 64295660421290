import type { ChatActivityAnswer } from '@app/@types/redux/answer';
import type { PageElement } from '@app/@types/redux/project';
import EmailMessenger from '@components/Email';
import logger from '@libs/log';
import { fetchSimulationEmails } from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { useEffect } from 'react';
import './InboxPage.scss';
interface InboxPageProps {
  pageElement: PageElement;
  currentAnswer: ChatActivityAnswer | undefined;
  answer: (answer: ChatActivityAnswer) => void;
}

const InboxPage: React.FC<InboxPageProps> = (props) => {
  const { pageElement } = props;
  const { id } = pageElement;
  const projectId = useAppSelector((state) => state.projects.currentProject.project.id);
  const dispatch = useAppDispatch();
  const userProjectEmails = useAppSelector((state) => state.projects.currentProject.emails);

  useEffect(() => {
    dispatch(fetchSimulationEmails({ projectId, pageElementId: id })).catch(() => {
      logger.error('Failed to fetch simulation emails');
    });
  }, []);

  return <EmailMessenger emails={userProjectEmails} />;
};

export default InboxPage;
