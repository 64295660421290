import type { PageElementProps } from '@components/Projects/TakeProject';
import { useEffect, useState, type FC } from 'react';

import type { CanvasAnswer, ExecutiveSummaryAnswer } from '@app/@types/redux/answer';
import type { ExecutiveSummaryOptions } from '@app/@types/redux/pageElement';
import { getPageElementDisabledState } from '@state/selectors/projects';
import ExecutiveSummaryView from './ExecutiveSummary';
import './ExecutiveSummary.scss';

export interface ExternalPageElementProps extends PageElementProps {
  answers?: CanvasAnswer;
  preview?: boolean;
}
const ExecutiveSummary: FC<PageElementProps> = (props) => {
  const { pageElement, answer, state } = props;

  const { id: pageElementId } = pageElement;
  const options = pageElement.attributes.options as ExecutiveSummaryOptions;
  const currentAnswer = props.currentAnswer as ExecutiveSummaryAnswer | undefined;

  const [answers, setAnswers] = useState<ExecutiveSummaryAnswer | undefined>(currentAnswer ?? {});

  const handleAnswerChange = (id: string, question: string, answer: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: {
        question,
        answer,
      },
    }));
  };

  useEffect(() => {
    answer(answers);
  }, [answers]);

  const disabled = getPageElementDisabledState(state, pageElementId);

  return (
    <ExecutiveSummaryView
      answers={answers}
      handleAnswerChange={handleAnswerChange}
      options={options}
      preview={disabled}
    />
  );
};
export default ExecutiveSummary;
