import { type Dispatch, type SetStateAction } from 'react';
import { Modal } from 'reactstrap';
import SimulationChat from './SimulationChat';

interface ChatModalProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}
function ChatModal({ modal, setModal }: ChatModalProps) {
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Modal isOpen={modal} toggle={toggle} style={{ width: '90vw', maxWidth: '90vw' }}>
      <SimulationChat />
    </Modal>
  );
}

export default ChatModal;
