import { SubmissionCardLinkAnswer } from '@app/@types/redux/answer';
import { redirectToExternal } from '@app/routes/helpers';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import cartedoPlaceholder from '../../../../../../assets/images/placeholders/cartedo_placeholder.png';
import api from '../../../../../../state/utils/Api';
import ReadMore from '../../../../../UI/Html/ReadMore';

interface LinkSubmissionProps {
  currentAnswer?: SubmissionCardLinkAnswer;
  answer?: (answer: SubmissionCardLinkAnswer) => void;
  preview?: boolean;
  link?: string;
  buttonIcon?: React.ReactNode;
  buttonText?: string;
}

const LinkSubmission: React.FC<LinkSubmissionProps> = (props) => {
  const { currentAnswer, answer, preview, link: linkParam, buttonIcon, buttonText } = props;

  const initialAnswer: SubmissionCardLinkAnswer = {
    link: '',
    linkMetaData: {
      image: '',
      title: '',
      description: '',
      url: '',
      favicon: '',
      siteName: '',
    },
    questions: {},
    type: 'link',
  };

  const link = preview ? linkParam : currentAnswer?.link;
  const [linkMetaData, setLinkMetaData] = useState(currentAnswer?.linkMetaData);

  useEffect(() => {
    if (link) {
      api
        .fetchMetaData(link)
        .then((res) => {
          const data = res.data.data;
          setLinkMetaData(data);
          if (!preview) {
            answer &&
              answer({
                ...(currentAnswer ?? initialAnswer),
                linkMetaData: data,
              });
          }
        })
        .catch(() => {
          setLinkMetaData({
            image: '',
            title: '',
            description: '',
            url: '',
            favicon: '',
            siteName: '',
          });
        });
    }
  }, [link]);

  if (!link) return null;

  const image = linkMetaData?.image;
  const title = linkMetaData?.title;
  const description = linkMetaData?.description;
  const url = linkMetaData?.url;

  return (
    <div className="d-flex justify-content-center mb-6 mt-3">
      {linkMetaData?.title && (
        <Card style={{ maxWidth: '500px' }}>
          {image && (
            <div
              style={{
                maxWidth: '100%',
                height: 'auto',
                aspectRatio: '16 / 9',
                objectFit: 'cover',
                background: `url(${cartedoPlaceholder}) center center / cover no-repeat`,
              }}
            >
              <img
                src={image}
                alt={title}
                className="card-img-top"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  aspectRatio: '16 / 9',
                  position: 'absolute',
                  background: '#e1e8ed',
                }}
                onError={(event) => ((event.target as HTMLImageElement).style.display = 'none')}
                onLoad={(event) => ((event.target as HTMLImageElement).style.display = 'block')}
              />
            </div>
          )}
          <CardBody>
            <h5>{title}</h5>
            <p>
              <ReadMore text={description} maxLength={200} />
            </p>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
            <br />
            <br />
            <Button
              color="primary"
              onClick={() => {
                redirectToExternal(link, '_blank');
              }}
            >
              <span className="btn-inner--icon mr-2">{buttonIcon}</span>
              <span className="btn-inner--text">{buttonText}</span>
            </Button>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default LinkSubmission;
