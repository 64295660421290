import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { URL_REGEX } from '../../../libs/constants/regex';
import i18n from '../../../libs/i18n/I18n';
import { redirectTo, redirectToExternal } from '../../../routes/helpers';
import { requestForgotPasswordToken } from '../../../state/redux/slices/auth.slice';
import { translate } from '../../../state/utils/helper';

import { DefaultError } from '@libs/constants/errors';
import { errorToast } from '@libs/helper';
import { useAppDispatch } from '@state/redux/store';
import { Input } from 'reactstrap';
import {
  A,
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  H4,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from '../../UI/Html';
import Footer from '../../UI/Layout/Footer';
import Header from './Header';

const SignIn = () => {
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [doneModal, setDoneModal] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.body.classList.add('bg-default-color');
    return () => {
      document.body.classList.remove('bg-default-color');
    };
  }, []);

  const trySignIn = () => {
    dispatch(requestForgotPasswordToken({ email }))
      .unwrap()
      .then(() => {
        setDoneModal(true);
      })
      .catch(() => {
        errorToast(DefaultError);
      });
  };

  return (
    <>
      <Alert
        isOpen={doneModal}
        onRequestClose={() => {
          setDoneModal(false);
        }}
        showCloseButton
        icon="success"
      >
        <Row className="mb-3">
          <Col>
            <div>
              <h1>Password Reset Request Sent</h1>
              <p>Please check your email and click on the provided link to reset your password</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="info"
              onClick={() => {
                setDoneModal(false);
                const url = `http://${email.split('@')[1]}`;
                if (URL_REGEX.test(url)) {
                  redirectToExternal(url);
                }
                redirectTo('/sign_in');
              }}
            >
              Check Mailbox
            </Button>
          </Col>
        </Row>
      </Alert>
      <Header
        title={translate('auth.forgotPassword.heading')}
        lead={translate('auth.forgotPassword.description')}
      />
      <Container className="mt--8 pb-5 main-container-sign-out">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <H4 className="text-center text-muted mb-5">
                  {translate('auth.forgotPassword.subHeading').toString()}
                </H4>
                <Form
                  role="form"
                  onSubmit={(e: { preventDefault: () => void }) => {
                    trySignIn();
                    e.preventDefault();
                  }}
                >
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={translate('auth.signInEmail')}
                        type="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setEmail(e.target.value);
                        }}
                        onFocus={() => {
                          setFocusedEmail(true);
                        }}
                        onBlur={() => {
                          setFocusedEmail(false);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      {i18n.t('auth.forgotPassword.sendResetInstructionButton').toString()}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <A className="text-white" href="/sign_in">
                  <small>{translate('auth.alreadyAccount')}</small>
                </A>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default SignIn;
