import type { PageElement } from '@app/@types/redux/project';
import type { SlideOverData } from '@components/UI/Html/SlideOver';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import { useAppSelector } from '@state/redux/store';
import { type Dispatch, type SetStateAction } from 'react';
import NoteSidePaneInner from '@components/Users/Notes/NoteSidePaneInner';

interface NotesProps {
  slideOverData: SlideOverData;
  setSlideOverData: Dispatch<SetStateAction<SlideOverData>>;
}
const Notes = (props: NotesProps) => {
  const { slideOverData, setSlideOverData } = props;
  const project = useAppSelector((state) => state.projects.currentProject);

  const filteredFeedbacks = project.project.attributes.projectStages.filter((stage) => {
    return stage.attributes.stageName.toLowerCase() === 'feedback';
  });
  const findFeedbackId = (data: PageElement[]) => {
    return data.find((item) => item.attributes.elementType === PAGE_ELEMENT.CARD_FEEDBACK);
  };

  return (
    <div>
      {slideOverData.width === 'l' ? (
        <div>
          {filteredFeedbacks.map((feedback) => {
            const rawId = findFeedbackId(feedback.attributes.pages[0].attributes.pageElements);
            if (!rawId) return null;
            const id = `feedback-${rawId.id}`;

            return (
              <>
                <div
                  key={feedback.id}
                  className="tf-container"
                  id={id}
                  onClick={() => {
                    setSlideOverData({
                      ...slideOverData,
                      isOpen: true,
                      header: feedback.attributes.parentStageName,
                      subHeader: 'Tedo Feedback',
                      width: '3xl',
                    });
                  }}
                >
                  <div className="tf-icon-container">
                    <i className={`ni ${feedback.attributes.icon} tf-icon-main`} />
                  </div>
                  <h2 className="tf-text">{feedback.attributes.parentStageName}</h2>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <NoteSidePaneInner />
      )}
    </div>
  );
};
export default Notes;
