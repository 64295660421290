import { nanoid } from '@reduxjs/toolkit';
import { fetchProjects } from '@state/redux/slices/project.slice';
import { RootState, useAppDispatch, useAppSelector } from '@state/redux/store';
import classnames from 'classnames';
import _ from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import { Helmet } from 'react-helmet';
import { FaAngleLeft, FaAngleRight, FaMoneyCheck, FaStripe } from 'react-icons/fa';
import { HiInformationCircle, HiOutlineGift } from 'react-icons/hi';
import { IoChevronForwardOutline, IoColorWandOutline } from 'react-icons/io5';
import { MdRefresh } from 'react-icons/md';
import { TbCertificate, TbFile, TbFileInvoice, TbLink } from 'react-icons/tb';
import { ActivityIndicator } from 'react-native-web';

import Skeleton from 'react-loading-skeleton';
import {
  Card,
  CardBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import { TITLE } from '../../../libs/constants/constants';
import { STRIPE_CHECKOUT_REGEX } from '../../../libs/constants/regex';
import { i18nCText } from '../../../libs/i18n/I18n';

import { redirectTo, redirectToExternal } from '@app/routes/helpers';
import { ROLES } from '@libs/constants/constants';
import { errorToast } from '@libs/helper';
import {
  fetchCurrentWorkspace,
  fetchUserWorkspace,
  verifyWorkspacePayment,
  verifyWorkspaceVoucher,
} from '@state/redux/slices/workspace.slice';
import { isLoggedIn } from '@utils/helpers/auth';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { toggleLoginModal, updateLocation } from '../../../state/redux/slices/auth.slice';
import { checkAnyPermission } from '../../../state/selectors/workspace';
import Api from '../../../state/utils/Api';
import { formatErrorCode, translate } from '../../../state/utils/helper';
import {
  Alert,
  Button,
  Col,
  Container,
  Div,
  Indicator,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  SubmitButton,
} from '../../UI/Html';
import ErrorAlert from '../../UI/Html/Form/Error';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';
import Filters from '../Filters';
import Title from '../View/Title';
import WorkspaceOwnerCard from '../WorkspaceOwnerCard';
import Item from './Item';

function loadingSkeleton() {
  return (
    <>
      {Array.from(Array(3), () => (
        <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-5" key={nanoid()}>
          <Skeleton height={300} />
          <div style={{ height: 20 }} />
          <Skeleton count={1} height={20} />
          <div style={{ height: 20 }} />
          <Skeleton count={3} height={20} />
        </Col>
      ))}
    </>
  );
}

function ProjectsList() {
  const dispatch = useAppDispatch();
  const workspaceId = useParams().workspaceId;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const isSignedIn = isLoggedIn();
  const projectsState = useAppSelector((state: RootState) => state.projects.projects);
  const totalProjects = useAppSelector((state: RootState) => state.projects.totalProjects);
  const currentWorkspace = useAppSelector((state) => state.workspaces.currentWorkspace);
  const userWorkspace = useAppSelector((state) => state.workspaces.userWorkspace);
  const [tab, setTab] = useState('overview');
  const [projects, setProjects] = useState(projectsState);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailureModal, setPaymentFailureModal] = useState(false);
  const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
  const [voucherSuccess, setVoucherSuccess] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [voucherModal, setVoucherModal] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    perPage: 9,
  });

  const [isFetching] = useState(false);
  const tabContainer = useRef(null);
  const [searchParams] = useSearchParams();
  const paymentParam = searchParams.get('payment');
  useEffect(() => {
    if (paymentParam === 'success') {
      setPaymentSuccess(true);
    }
    if (paymentParam === 'fail') {
      setPaymentFailureModal(true);
    }
  }, [paymentParam]);
  useEffect(() => {
    setProjects(projectsState);
  }, [projectsState]);
  const filters = useAppSelector((state: RootState) => state.projects.filters);
  useEffect(() => {
    if (!workspaceId) {
      return;
    }
    setLoading(true);
    dispatch(fetchCurrentWorkspace(workspaceId))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    dispatch(
      fetchProjects({
        workspaceId,
        page: params.page,
        perPage: params.perPage,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    dispatch(fetchUserWorkspace({ workspaceId }))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [params, workspaceId, isSignedIn, filters]);

  function switchPage(pageNumber: number) {
    setParams({
      ...params,
      page: pageNumber,
    });
  }
  const { page, perPage } = params;
  const pages = Math.ceil(totalProjects / perPage);

  function renderPages() {
    const currentPageIndex = page;
    return _.range(1, pages + 1).map((p, index) => {
      const pId = p;
      const element = (
        <PaginationItem key={pId} className={page === pId ? 'active' : ''}>
          <PaginationLink
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              switchPage(pId);
            }}
          >
            {index + 1}
          </PaginationLink>
        </PaginationItem>
      );
      if (
        index === 0 ||
        pages - 1 === index ||
        (currentPageIndex - 3 < index && currentPageIndex + 3 > index)
      ) {
        return element;
      }
      if (currentPageIndex - 3 === index || currentPageIndex + 3 === index) {
        return (
          <PaginationItem key={pId} className="disabled">
            <PaginationLink
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        );
      }
      return '';
    });
  }
  const previousPage = params.page - 1;
  let nextPage = params.page + 1;
  if (nextPage >= pages) {
    nextPage = 0;
  }

  let buttonLabel = 'Loading...';
  const userWorkspaceRoles = userWorkspace.attributes.userWorkspaceRoles;
  const costOfWorkspace = currentWorkspace.attributes.options.cost || 0;
  // const workspaceReportLink = userWorkspace.attributes.report
  //   ? `${env.TEACHER_PLATFORM_URL}/workspace_report/${userWorkspace.attributes.report}`
  //   : false;

  const workspaceCertificateLink = userWorkspace.attributes.certificateUrl
    ? userWorkspace.attributes.certificateUrl
    : false;
  let isAllowed = checkAnyPermission(userWorkspaceRoles, [ROLES.ACCESS_APPROVED, ROLES.TESTER]);

  if (costOfWorkspace === 0) {
    isAllowed = true;
  }

  if (!isSignedIn) {
    isAllowed = false;
  }

  if (isAllowed) {
    buttonLabel = translate('workspace.accessWorkspace');
  } else {
    buttonLabel = translate('workspace.purchaseWorkspace');
  }

  if (!isSignedIn && costOfWorkspace === 0) {
    buttonLabel = translate('auth.signInToAccess');
  }

  const voucherText = () => {
    if (!isAllowed) {
      return (
        <div className="pr-1 text-center text-lg-right">
          <Button
            color="link"
            style={{ fontSize: '0.8em' }}
            onClick={(e) => {
              if (!isSignedIn) {
                dispatch(updateLocation(location));
                return dispatch(toggleLoginModal(true));
              }
              e.preventDefault();
              setPaymentModal(false);
              setVoucherModal(true);
            }}
          >
            Have a voucher code? Redeem here
          </Button>
        </div>
      );
    }
  };
  useEffect(() => {
    if (currentWorkspace.attributes.options.overview) {
      setTab('overview');
    } else {
      setTab('challenges');
    }
  }, [currentWorkspace]);
  const showOverview = Boolean(currentWorkspace.attributes.options.overview);
  const showChallenges = Boolean(projects.length);
  const showResources = Boolean(currentWorkspace.attributes.options.resources?.length ?? 0);

  if (!workspaceId) {
    return null;
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${translate('layout.navbar.navlinks.projects')} | ${TITLE}`}</title>
      </Helmet>
      <Alert
        isOpen={voucherSuccess}
        onRequestClose={() => {
          setVoucherSuccess(false);
        }}
        showCloseButton
        icon="success"
      >
        <MemoVVS
          text="Voucher redeemed"
          description="You have successfully redeemed your voucher. You can now access the workspace."
        />
        <Button
          color="info"
          className="btn-icon btn-3 btn-round"
          onClick={() => {
            setVoucherSuccess(false);
          }}
        >
          <span className="btn-inner--text">{translate('workspace.accessWorkspace')}</span>
          <span className="btn-inner--icon">
            <IoChevronForwardOutline />
          </span>
        </Button>
      </Alert>
      <Alert
        isOpen={paymentFailureModal}
        onRequestClose={() => {
          setPaymentFailureModal(false);
        }}
        showCloseButton
        icon="error"
      >
        <center>
          <h3 className="workspaceItem__verifyVoucherSuccess">Payment failed</h3>
          <p className="mt-2 mb-4">Your payment failed. Please try again.</p>
          <Button
            color="info"
            className="btn-icon btn-3 btn-round"
            onClick={() => {
              setPaymentFailureModal(false);
              setPaymentModal(true);
            }}
          >
            <span className="btn-inner--icon">
              <MdRefresh style={{ fontSize: '1.5rem' }} />
            </span>
            <span className="btn-inner--text">Try again</span>
          </Button>
        </center>
      </Alert>
      <Alert
        isOpen={paymentSuccessModal}
        onRequestClose={() => {
          setPaymentSuccessModal(false);
        }}
        showCloseButton
        icon="success"
      >
        <MemoVVS
          text="Payment successful"
          description="Congratulations! Your payment is successful. You will receive the invoice in your email. You can now access the workspace."
        />
        <Button
          color="default"
          className="btn-icon btn-3 btn-round"
          onClick={() => {
            redirectToExternal(userWorkspace.attributes.invoiceUrl, '_blank');
          }}
        >
          <span className="btn-inner--icon">
            <TbFileInvoice style={{ fontSize: '20px' }} />
          </span>
          <span className="btn-inner--text">View Invoice</span>
        </Button>
        <Button
          color="info"
          className="btn-icon btn-3 btn-round"
          onClick={() => {
            setPaymentSuccessModal(false);
          }}
        >
          <span className="btn-inner--text">{translate('workspace.accessWorkspace')}</span>
          <span className="btn-inner--icon">
            <IoChevronForwardOutline style={{ fontSize: '20px' }} />
          </span>
        </Button>
      </Alert>
      <Alert
        isOpen={voucherModal && !isAllowed}
        onRequestClose={() => {
          setVoucherModal(false);
        }}
        showCloseButton
      >
        <VerifyVoucher
          workspaceId={workspaceId ? workspaceId : ''}
          voucherSuccess={() => {
            setVoucherModal(false);
            setVoucherSuccess(true);
          }}
        />
      </Alert>
      <Alert
        isOpen={paymentModal}
        onRequestClose={() => {
          setPaymentModal(false);
        }}
        showCloseButton
      >
        <StripePayment
          costOfWorkspace={costOfWorkspace}
          workspaceId={workspaceId}
          setPaymentModal={setPaymentModal}
          setVoucherModal={setVoucherModal}
        />
      </Alert>
      <Alert isOpen={paymentSuccess && !isAllowed}>
        <VerifyPayment
          workspaceId={workspaceId}
          successFn={() => {
            setPaymentSuccessModal(true);
          }}
          errorFn={() => {
            setPaymentSuccess(false);
            setPaymentFailureModal(true);
          }}
        />
      </Alert>
      <Container fluid>
        <Div className="px-md-3 pt-md-3 view-challenge">
          <Row className="mt-3 mb-4">
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Card className="workspace__view_imageCard" style={{ height: '100%' }}>
                {isFetching ? (
                  <div
                    style={{
                      marginTop: '-4px',
                    }}
                  >
                    <Skeleton height="45vw" />
                  </div>
                ) : (
                  <>
                    <img
                      className="rounded project-preview-card-img"
                      alt={i18nCText(currentWorkspace.attributes.name)}
                      src={currentWorkspace.attributes.image}
                    />
                    <div className="project__item__imageOverlay">
                      <div className="project__item__imageOverlay__content">
                        {i18nCText(currentWorkspace.attributes.name)}
                      </div>
                    </div>
                  </>
                )}
              </Card>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Card style={{ height: '100%' }}>
                <CardBody>
                  {isFetching ? (
                    <Skeleton count={3} />
                  ) : (
                    <div className="fadeInUp">
                      <div className="head-description">
                        <Title>{i18nCText(currentWorkspace.attributes.name)}</Title>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: i18nCText(
                              currentWorkspace.attributes.options.description || ''
                            ),
                          }}
                        />
                        <br />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
              {currentWorkspace.attributes.options.owner ? (
                <WorkspaceOwnerCard
                  loading={isFetching}
                  name={currentWorkspace.attributes.options.owner}
                  image={currentWorkspace.attributes.options.ownerImage}
                />
              ) : null}
              {!isAllowed ? (
                <>
                  <Card
                    className="border-0 cursor-pointer apply-button"
                    onClick={() => {
                      if (!isSignedIn) {
                        dispatch(updateLocation(location));
                        return dispatch(toggleLoginModal(true));
                      }
                      if (!loading) {
                        setPaymentModal(true);
                      }
                    }}
                    tag="h5"
                    style={{
                      marginBottom: '2px',
                    }}
                  >
                    <CardBody className="apply-button-body">
                      <Row className="mobile-button-safe-area">
                        <div className="col">
                          <h1 className="pt-2 text-white display-3 apply-button-text">
                            {loading ? 'Loading...' : buttonLabel}
                          </h1>
                        </div>
                        <Col className="col-auto">
                          <h1 className="pt-2 text-white display-3 apply-button-text">
                            {loading
                              ? '...'
                              : costOfWorkspace > 0
                              ? `$${String(costOfWorkspace)}`
                              : ''}
                          </h1>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {costOfWorkspace > 0 ? <div className="">{voucherText()}</div> : null}
                </>
              ) : (
                <Card
                  id="view_report_tooltip"
                  className="mb-0 border-0 bg-gradient-success cursor-pointer"
                  tag="h5"
                  onClick={() => {
                    if (!loading) {
                      setTab('challenges');
                      const tabElement = document.querySelector('#tabContainer');

                      if (tabElement) {
                        tabElement.scrollIntoView({ behavior: 'smooth' });
                        (tabElement as HTMLElement).click();
                      }
                    }
                  }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <h1 className="text-white display-3">
                          {loading ? 'Loading...' : buttonLabel}
                        </h1>
                      </div>
                      <Col className="col-auto">
                        {/* {workspaceReportLink ? (
                          <>
                            <Button
                              id="view_report_tooltip"
                              className="mx-1 btn-icon btn-3 invite-friends-btn"
                              color="white"
                              size="lg"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(workspaceReportLink, '_blank');
                              }}
                            >
                              <span className="btn-inner--icon">
                                <TbReportAnalytics style={{ fontSize: '2em' }} />
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="view_report_tooltip"
                            >
                              ss
                            </UncontrolledTooltip>
                          </>
                        ) : null} */}
                        {workspaceCertificateLink ? (
                          <>
                            <Button
                              id="certificate_tooltip"
                              className="mx-1 btn-icon btn-3 invite-friends-btn"
                              color="white"
                              size="lg"
                              onClick={(e) => {
                                e.preventDefault();
                                redirectToExternal(workspaceCertificateLink, '_blank');
                              }}
                            >
                              <span className="btn-inner--icon">
                                <TbCertificate style={{ fontSize: '2em' }} />
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="certificate_tooltip"
                            >
                              {i18nCText('Download Certificate')}
                            </UncontrolledTooltip>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Div>
        <div ref={tabContainer} id="tabContainer">
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div className="p-0 px-3 nav-wrapper">
                <Nav className="nav-fill overviewPageNav" id="tabs-icons-text" pills role="tablist">
                  {showOverview ? (
                    <NavItem style={{ marginBottom: '1rem' }}>
                      <NavLink
                        aria-selected={tab === 'overview'}
                        className={classnames('mb-sm-3 mb-md-0 font-weight-600', {
                          active: tab === 'overview',
                        })}
                        style={{
                          fontSize: '1rem',
                        }}
                        onClick={() => {
                          setTab('overview');
                        }}
                        href="#"
                        role="tab"
                      >
                        <HiInformationCircle className="mr-2" style={{ fontSize: '1.2rem' }} />{' '}
                        {translate('projectPreview.section.overview.label')}
                      </NavLink>
                    </NavItem>
                  ) : null}
                  {showChallenges ? (
                    <NavItem style={{ marginBottom: '1rem' }}>
                      <NavLink
                        aria-selected={tab === 'challenges'}
                        className={classnames('mb-sm-3 mb-md-0 font-weight-600', {
                          active: tab === 'challenges',
                        })}
                        style={{
                          fontSize: '1rem',
                        }}
                        onClick={() => {
                          setTab('challenges');
                        }}
                        href="#"
                        role="tab"
                      >
                        {translate('projectPreview.section.challenges.label')}
                      </NavLink>
                    </NavItem>
                  ) : null}
                  {showResources ? (
                    <NavItem style={{ marginBottom: '1rem' }}>
                      <NavLink
                        aria-selected={tab === 'resources'}
                        className={classnames('mb-sm-3 mb-md-0 font-weight-600', {
                          active: tab === 'resources',
                        })}
                        style={{
                          fontSize: '1rem',
                        }}
                        onClick={() => {
                          setTab('resources');
                        }}
                        href="#"
                        role="tab"
                      >
                        <HiInformationCircle className="mr-2" style={{ fontSize: '1.2rem' }} />{' '}
                        {translate('takeProject.resources')}
                      </NavLink>
                    </NavItem>
                  ) : null}
                </Nav>
              </div>
              <TabContent activeTab={`tabs${tab}`}>
                {showOverview ? (
                  <TabPane tabId="tabsoverview" className="fadeInUp pt-md-3 px-md-3">
                    {isFetching ? (
                      <Skeleton count={3} />
                    ) : (
                      <div className="fadeInUp">
                        <Card className="rounded">
                          <CardBody>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: i18nCText(
                                  currentWorkspace.attributes.options.overview || ''
                                ),
                              }}
                            />
                          </CardBody>
                        </Card>
                      </div>
                    )}
                  </TabPane>
                ) : null}
                {showChallenges ? (
                  <TabPane tabId="tabschallenges" className="fadeInUp pt-md-3 px-md-3">
                    {isFetching ? (
                      <Skeleton count={3} />
                    ) : (
                      <div className="fadeInUp">
                        <Row className="main-container">
                          <Col>
                            <Row className="mt-4">
                              <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Filters />
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                                <Row>
                                  <Col>
                                    <h2 className="mt-1 mb-4 text-uppercase font-weight-600 d-flex">
                                      {translate('projectsIndex.title')}
                                    </h2>
                                  </Col>
                                </Row>
                                <Row>
                                  {
                                    // eslint-disable-next-line no-nested-ternary
                                    loading ? (
                                      loadingSkeleton()
                                    ) : projects.length > 0 ? (
                                      projects.map((item, index) => (
                                        <Col
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={4}
                                          xl={4}
                                          className="d-flex fadeInUp"
                                          key={item.id}
                                          style={{
                                            animationDelay: `${String(0.1 + index * 0.1)}s`,
                                          }}
                                        >
                                          <Item
                                            data={item.attributes}
                                            locked={!isAllowed}
                                            workspaceId={workspaceId}
                                          />
                                        </Col>
                                      ))
                                    ) : (
                                      <p className="ml-3">{translate('projectsIndex.empty')}</p>
                                    )
                                  }
                                </Row>
                                <Row>
                                  <Col>
                                    <Pagination
                                      className={classnames('pagination justify-content-end mb-0')}
                                      listClassName="justify-content-end mb-0"
                                    >
                                      <PaginationItem className={previousPage ? '' : 'disabled'}>
                                        <PaginationLink
                                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                            e.preventDefault();
                                            switchPage(previousPage);
                                          }}
                                          tabIndex="-1"
                                        >
                                          <FaAngleLeft />
                                          <span className="sr-only">
                                            {translate('takeProject.previous')}
                                          </span>
                                        </PaginationLink>
                                      </PaginationItem>
                                      {renderPages()}
                                      <PaginationItem className={nextPage ? '' : 'disabled'}>
                                        <PaginationLink
                                          href="#"
                                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                            e.preventDefault();
                                            switchPage(nextPage);
                                          }}
                                        >
                                          <FaAngleRight />
                                          <span className="sr-only">
                                            {translate('takeProject.next')}
                                          </span>
                                        </PaginationLink>
                                      </PaginationItem>
                                    </Pagination>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </TabPane>
                ) : null}
                {showResources ? (
                  <TabPane tabId="tabsresources" className="fadeInUp pt-md-3 px-md-3">
                    {isFetching ? (
                      <Skeleton count={3} />
                    ) : (
                      <div className="fadeInUp">
                        <Row className="main-container">
                          <Col>
                            <Row className="mt-4">
                              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Row>
                                  <Col>
                                    <h2 className="mt-1 mb-4 text-uppercase font-weight-600 d-flex">
                                      Resources
                                    </h2>
                                  </Col>
                                </Row>
                                <Row>
                                  {
                                    // eslint-disable-next-line no-nested-ternary
                                    loading ? (
                                      loadingSkeleton()
                                    ) : currentWorkspace.attributes.options.resources &&
                                      currentWorkspace.attributes.options.resources.length > 0 ? (
                                      currentWorkspace.attributes.options.resources.map(
                                        (item, index) => (
                                          <Col
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={3}
                                            xl={3}
                                            className="d-flex fadeInUp"
                                            key={item.id}
                                            style={{
                                              animationDelay: `${String(0.1 + index * 0.1)}s`,
                                            }}
                                          >
                                            <Card className="project__item__card">
                                              <div className="project__item__cardImage">
                                                <div className="project__item__imageContainer">
                                                  <div className="project__item__iconContainer">
                                                    {item.type === 'file' ? <TbFile /> : <TbLink />}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="project__item__cardBody">
                                                <h3
                                                  style={{
                                                    overflowWrap: 'break-word',
                                                  }}
                                                >
                                                  <EllipsisText text={item.name} length={32} />
                                                </h3>
                                                <div className="project__item__buttonContainer">
                                                  <a
                                                    color="link"
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    View Resource
                                                  </a>
                                                </div>
                                                <Row>
                                                  <Col className="text-right d-flex" />
                                                </Row>
                                              </div>
                                            </Card>
                                          </Col>
                                        )
                                      )
                                    ) : (
                                      <p className="ml-3">There are no resources</p>
                                    )
                                  }
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </TabPane>
                ) : null}
              </TabContent>
            </Col>
          </Row>
        </div>
        <Footer />
      </Container>
    </Layout>
  );
}

interface Props {
  workspaceId: string;
  successFn: () => void;
  errorFn: () => void;
}

function VerifyPayment(props: Props) {
  const { workspaceId, successFn, errorFn } = props;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(verifyWorkspacePayment({ workspaceId, successFn, errorFn })).catch(() => {
      errorToast(formatErrorCode('ERCTLNR-E6968S8'));
    });
  }, [errorFn, successFn, workspaceId]);
  return (
    <>
      <h3 className="workspaceItem__verifyVoucherSuccess">Verifying payment...</h3>
      <br />
      <Indicator />
    </>
  );
}

interface VoucherProps {
  workspaceId: string;
  voucherSuccess: () => void;
}

function VerifyVoucher(props: VoucherProps) {
  const { workspaceId, voucherSuccess } = props;
  const [input, setInput] = useState('');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  return (
    <div className="px-2 p-lg-4">
      <div className="workspaceItem__verifyVoucher_icon">
        <span>
          <HiOutlineGift />
        </span>
      </div>
      <h3 className="mb-4 workspaceItem__verifyVoucherSuccess">Redeem Voucher</h3>
      {/* <p className='pb-2'>
        Enter the voucher code below to access this workspace. If you don't have a voucher code,
        please contact your instructor.
      </p> */}
      <div className="workspaceItem__verifyVoucher_inputGroup">
        <div className="workspaceItem__verifyVoucher_inputDiv">
          <Input
            type="text"
            value={input}
            placeholder="Enter voucher code"
            className="workspaceItem__verifyVoucher_input"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setError('');
              let value = (e.target as HTMLInputElement).value;
              value = value.replace(/[^A-Za-z0-9]/g, '');
              value = value.replace(/-/g, '');
              value = value.match(/.{1,4}/g)?.join('-') ?? '';
              setInput(value.toUpperCase());
            }}
            maxLength={19}
            invalid={error}
            pattern="[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}"
          />
          <ErrorAlert errors={Boolean(error)}>{error}</ErrorAlert>
        </div>
        <SubmitButton
          className="btn-icon btn-3"
          loading={loading}
          disabled={loading || input.length !== 19}
          onClick={() => {
            setLoading(true);
            dispatch(
              verifyWorkspaceVoucher({
                workspaceId,
                voucherCode: input,
                successCb: () => {
                  voucherSuccess();
                },
                errorCb: () => {
                  setError('Invalid voucher code');
                  setLoading(false);
                },
              })
            ).catch(() => {
              errorToast(formatErrorCode('ERCTLNR-CGNQQSH'));
            });
          }}
        >
          {!loading && (
            <span className="btn-inner--icon">
              <IoColorWandOutline />
            </span>
          )}
          <span className="mx-2 btn-inner--text">Redeem</span>
        </SubmitButton>
      </div>

      <div>
        <Button color="link" className="workspaceItem__verifyVoucher_contactUs">
          Need help? Contact us
        </Button>
      </div>
    </div>
  );
}

function VerifyVoucherSuccess({
  text = 'Workspace unlocked successfully.',
  description = 'You can now access the workspace.',
}) {
  return (
    <center>
      <ConfettiExplosion />
      <h3 className="workspaceItem__verifyVoucherSuccess">{text}</h3>
      <p className="mt-2 mb-4">{description}</p>
    </center>
  );
}

const MemoVVS = memo(VerifyVoucherSuccess);

export default ProjectsList;

function StripePayment(props: {
  costOfWorkspace: number;
  workspaceId: string;
  setPaymentModal: (value: boolean) => void;
  setVoucherModal: (value: boolean) => void;
}) {
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const { costOfWorkspace, workspaceId, setPaymentModal, setVoucherModal } = props;

  useEffect(() => {
    if (workspaceId) {
      setPaymentLinkLoading(true);
      Api.getPaymentUrl(workspaceId)
        .then((res) => {
          if ('url' in res.data) {
            if (!res.data.url || !STRIPE_CHECKOUT_REGEX.test(res.data.url)) {
              throw new Error('ERCTLNR-HYC8VL9');
            } else {
              setPaymentLink(res.data.url);
              setPaymentLinkLoading(false);
              return;
            }
          }
          if ('data' in res.data) {
            if (res.data.data.attributes.userWorkspaceRoles.includes(ROLES.ACCESS_APPROVED)) {
              setPaymentModal(false);
              redirectTo(`/workspace/${workspaceId}?payment=success`);
              return;
            }
          }
        })
        .catch((e: unknown) => {
          setPaymentLinkLoading(false);
          setPaymentModal(false);
          errorToast(formatErrorCode((e as Error).message || 'ERCTLNR-YG6BZO1'));
        });
    }
  }, [workspaceId]);
  return (
    <div className="p-4">
      <div className="workspaceItem__verifyVoucher_icon">
        <span>
          <FaMoneyCheck />
        </span>
      </div>
      <h3 className="mb-4 workspaceItem__verifyVoucherSuccess">Pay ${costOfWorkspace}</h3>
      <div>
        <a
          className={classnames(
            'mt-4 btn-icon btn-3 btn-block btn-round workspaceItem__paymentButton btn btn-info',
            {
              disabled: paymentLinkLoading,
            }
          )}
          href={paymentLink}
          target="_self"
          about="Pay"
        >
          {!paymentLinkLoading ? (
            <span className="btn-inner--icon">
              <FaStripe style={{ fontSize: '2rem' }} />
            </span>
          ) : (
            <span className="btn-inner--icon--loading">
              <ActivityIndicator animating size="small" color="#ffffff" />
            </span>
          )}
          <span className="mx-2 btn-inner--text">Pay ${costOfWorkspace}</span>
        </a>
      </div>
      <div className="mt-2">
        <Button
          color="link"
          className="text-muted"
          style={{ fontSize: '0.8em' }}
          onClick={(e) => {
            e.preventDefault();
            setPaymentModal(false);
            setVoucherModal(true);
          }}
        >
          Have a voucher code? Redeem here
        </Button>
      </div>
    </div>
  );
}
