import { getAccessToken, setRedirectToPath } from '@state/utils/helper';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

const Protected = () => {
  const token = getAccessToken();
  return token ? <Outlet /> : <Redirect />;
};

interface LocationState {
  from?: {
    pathname: string;
    search: string;
  };
}

const Redirect = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;
  const location = useLocation();

  const url = location.state as LocationState | undefined;
  setRedirectToPath(
    JSON.stringify({
      pathname: url?.from?.pathname ?? pathname,
      search: url?.from?.search ?? search,
    })
  );
  return <Navigate to="/sign_in" replace />;
};

export default Protected;
