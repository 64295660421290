import { nanoid } from '@reduxjs/toolkit';
import avatar1 from '../../assets/images/placeholders/avatar-1.jpg';
import avatar2 from '../../assets/images/placeholders/avatar-2.jpg';

export interface IChat {
  id: string;
  name: string;
  lastMessage: string;
  messageStatus: string;
  lastMessageTime: string;
  profilePicture: string;
  online: boolean;
  itemType?: string;
}
export const Chats: IChat[] = [
  {
    id: nanoid(),
    name: 'Jacob Mcleod',
    lastMessage: 'Thanks for the help!',
    messageStatus: 'locked',
    lastMessageTime: '3:01 PM',
    profilePicture: avatar1,
    online: false,
  },
  {
    id: nanoid(),
    name: 'Jasmin Lowery',
    lastMessage: 'See you later!',
    messageStatus: 'locked',
    lastMessageTime: '3:02 PM',
    profilePicture: avatar2,
    online: false,
  },
];
