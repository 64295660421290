import { LocationData } from '@app/@types/redux/auth';
import { Page, Project, ProjectStage } from '@app/@types/redux/project';
import _, { isEqual } from 'lodash';
import moment from 'moment';
import reactStringReplace from 'react-string-replace';
import { toast } from 'sonner';
import { redirectTo } from '../routes/helpers';
import { DEFAULT_PATH } from './constants/constants';
import i18n from './i18n/I18n';
import logger from './log';

export const calculateAge = (date: Date) => moment().diff(moment(date), 'years');

export const isConfirmed = () => true;

export const prettifyDate = (date: Date) => {
  return moment(date).format('MMM DD YYYY, h:mm:ss a');
};

export function errorToast(message = 'Some error occurred') {
  toast.error(message);
}

export function successToast(message = 'Done') {
  toast.success(message);
}

const hasNumber = (value: string) => {
  return new RegExp(/[0-9]/).test(value);
};

const hasMixed = (value: string) => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};

const hasSpecial = (value: string) => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

export const strengthIndicator = (value: string) => {
  let strengths = 0;
  if (value.length > 5) strengths += 1;
  if (value.length > 7) strengths += 1;
  if (hasNumber(value)) strengths += 1;
  if (hasSpecial(value)) strengths += 1;
  if (hasMixed(value)) strengths += 1;
  return strengths;
};

export const slugify = (string: string): string => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const isEmail = (email: string) => {
  if (email) {
    return /^[A-Za-z0-9+_.-]{1,64}@[A-Za-z0-9.-]{1,255}$/.test(email);
  }
  return false;
};

export const isValidBirthDate = (birthDate: string) => {
  if (birthDate) {
    return moment(birthDate).isValid();
  }
  return false;
};

export const isGender = (gen: string) => {
  if (gen) {
    return ['male', 'female', 'other'].includes(gen);
  }
  return false;
};

export const redirectToPrevious = (previousLocation?: LocationData) => {
  const redirectLocation = previousLocation?.pathname
    ? `${previousLocation.pathname}/${previousLocation.search}`
    : DEFAULT_PATH;
  redirectTo(redirectLocation);
};

export function changeLanguage(code: string, language: string) {
  i18n
    .changeLanguage(code, () => {
      successToast(
        i18n.t('messages.languageUpdateSuccess', {
          language,
        })
      );
      window.location = window.location.href.split('?')[0] as unknown as Location;
    })
    .catch(() => {
      logger.error('Error changing language');
    });
}

export function mode(array: string[]) {
  // calculate mode of an array of strings
  if (array.length === 0) return null;
  const modeMap: Record<string, number> = {}; // Add index signature
  let maxEl = array[0];
  let maxCount = 1;
  for (const el of array) {
    modeMap[el] = (modeMap[el] || 0) + 1;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
}

export function calcHeight(
  el: HTMLElement,
  setHeight: React.Dispatch<React.SetStateAction<number>>
) {
  const tempHeight = el.offsetHeight;
  setHeight(tempHeight);
}

export const isArrayEqual = (x: unknown[], y: unknown[]) => {
  return _(x).xorWith(y, isEqual).isEmpty();
};

export function redirectToChallengePageUrl(
  project: Project,
  stage: ProjectStage,
  page: Page,
  workspaceId: string
) {
  return `/workspace/${workspaceId}/challenge/${project.id}/${stage.id}/${page.id}/${slugify(
    `${stage.attributes.stageName || stage.attributes.stageType} ${page.attributes.title}`
  )}`;
}

export function redirectToChallengePage(
  project: Project,
  stage: ProjectStage,
  page: Page,
  workspaceId: string
) {
  redirectTo(redirectToChallengePageUrl(project, stage, page, workspaceId));
}

export const getVimeoIdFromUrl = (url: string) => {
  const match = /vimeo.*\/(\d+)/i.exec(url);
  if (match) {
    return match[1];
  }
  return false;
};

const regex = /(<([^>]+)>)/gi;

export function stripHtml(text: string): string {
  return text.replace(regex, '');
}

export function formatUnixDate(date: number | string, format = 'MMM DD, YYYY') {
  return moment.unix(Number(date)).format(format);
}

export function formatDate(date: string, format = 'MMM DD, YYYY') {
  return moment(date).format(format);
}

export function getParsedEmailBody(name: string, body: string) {
  const parsedBody = reactStringReplace(body, `{{{recipientName}}}`, () => {
    return name;
  }).join('');

  return parsedBody;
}
