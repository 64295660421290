import { useEffect, useState } from 'react';

import {
  CanvasAnswer,
  CardFeedbackAnswer,
  CardReviewAnswer,
  CustomerJourneyMapAnswer,
  PersonaEntryAnswer,
  PrioritizationMatrixAnswer,
  SubmissionCardAnswer,
  type ChatActivityAnswer,
  type EmailActivityAnswer,
  type ExecutiveSummaryAnswer,
} from '@app/@types/redux/answer';
import {
  CanvasOptions,
  CardFeedbackOptions,
  CustomerJourneyMapOptions,
  PrioritizationMatrixOptions,
  SubmissionCardOptions,
  type ChatActivityOptions,
  type ExecutiveSummaryOptions,
} from '@app/@types/redux/pageElement';
import { UserAnswerAnswer, UserAnswerFeedback } from '@app/@types/redux/project';
import ChatBox from '@components/Chat/ChatBox';
import { EmailView } from '@components/Email';
// import { SummaryView } from '@components/ExecutiveSummary';
import ExecutiveSummaryView from '@components/ExecutiveSummary/ExecutiveSummary';
import TedoFeedback from '@components/Projects/TedoFeedback';
import SlideOver, { type SlideOverData } from '@components/UI/Html/SlideOver';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import logger from '@libs/log';
import { fetchFeedback, submitFeedback } from '@state/redux/slices/project.slice';
import { BiTrendingDown } from 'react-icons/bi';
import Select from 'react-select';
import { Badge, Row } from 'reactstrap';
import { PageElementProps } from '..';
import tedoIcon from '../../../../assets/icons/tedo.png';
import { i18nCText } from '../../../../libs/i18n/I18n';
import { useAppDispatch, useAppSelector } from '../../../../state/redux/store';
import { getCurrentProject, getFeedback } from '../../../../state/selectors/projects';
import { translate } from '../../../../state/utils/helper';
import {
  Card,
  CardBody,
  Col,
  H3,
  H4,
  ListGroup,
  ListGroupItem,
  StarRatings,
  Table,
  UncontrolledTooltip,
} from '../../../UI/Html';
import Title from '../../View/Title';
import CanvasView from '../Pages/ModelCanvas/CanvasView';
import CardPersona from './HelperComponents/CardPersona';
import CustomerJourneyMapView from './HelperComponents/CustomerJourneyMapView';
import PrioritizationMatrixView from './HelperComponents/PrioritizationMatrixView';
import SubmissionType from './HelperComponents/SubmissionType';
import ReviewSubmissionCard from './ReviewSubmissionCard';
interface Option {
  value: string;
  label: string;
}

const feedbackOptions: Option[] = [
  {
    value: '-',
    label: 'Select an option',
  },
  {
    value: '1',
    label: translate('pageElements.cardFeedback.actionLabels.accept'),
  },
  {
    value: '0',
    label: translate('pageElements.cardFeedback.actionLabels.ignore'),
  },
  {
    value: '-1',
    label: translate('pageElements.cardFeedback.actionLabels.reportAbuse'),
  },
];
export function renderQuestion(
  question: string,
  rating: number | undefined,
  feedback: string | undefined
) {
  return (
    <ListGroupItem
      className="checklist-entry flex-column align-items-start py-2 px-2"
      key={question}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <h4 className="checklist-title mb-0 pl-2">
            <span
              dangerouslySetInnerHTML={{
                __html: i18nCText(question),
              }}
            />
            <h6 className="cardFeedback__aiFeedback">{feedback}</h6>
          </h4>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-lg-right">
          {rating ? <StarRatings className="" disabled value={rating} small /> : null}
        </Col>
      </Row>
    </ListGroupItem>
  );
}
const CardFeedback: React.FC<PageElementProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector((state) => getCurrentProject(state.projects));
  const feedbacks = useAppSelector((state) => getFeedback(state.projects));

  const name = useAppSelector((state) => state.users.user.attributes.name);
  const toEmail = useAppSelector((state) => state.users.user.attributes.email);
  const toImage = useAppSelector((state) => state.users.user.attributes.avatarUrl);
  const [slideOverData, setSlideOverData] = useState<SlideOverData>({
    id: '',
    isOpen: false,
    onClose: () => {
      setSlideOverData({ ...slideOverData, isOpen: false });
    },
    width: 'l',
    header: 'Tedo Feedback',
  });
  const { id: currentProjectId } = currentProject;
  const { id: pageElementId } = props.pageElement;

  const {
    pageElement: {
      attributes: { options: peOptions, dependees },
    },
    getDependeeAnswerType,
    getPageElement,
  } = props;

  useEffect(() => {
    dispatch(fetchFeedback({ projectId: currentProjectId, pageElementId })).catch(() => {
      logger.error('Error fetching feedback');
    });
  }, [pageElementId, currentProjectId, dispatch]);

  function rateFeedback(feedbackRating: string, userAnswerFeedbackId: string) {
    const {
      currentAnswer,
      pageElement: { id },
    } = props;
    const answer = props.answer as (answer: CardFeedbackAnswer) => void;
    const currentAnswerSafe = currentAnswer as CardFeedbackAnswer;
    answer({
      ...currentAnswerSafe,
      [userAnswerFeedbackId]: { userAnswerFeedbackId, feedbackRating },
    });
    dispatch(
      submitFeedback({
        answer: {
          ...currentAnswerSafe,
          [userAnswerFeedbackId]: { userAnswerFeedbackId, feedbackRating },
        },
        pageElementId: id,
      })
    ).catch(() => {
      logger.error('Error submitting feedback');
    });
  }

  function feedbackTableSummary(peerRatings: UserAnswerFeedback[] | undefined) {
    if (!peerRatings?.length) {
      return (
        <Col>
          {/* Added this emoji so that no one gets disheartened */}
          <H3 className="text-center">&nbsp;</H3>
        </Col>
      );
    }

    const { currentAnswer } = props;

    const currentAnswerSafe = currentAnswer as CardFeedbackAnswer | undefined;
    return (
      <Row>
        <Table className="align-items-center table-flush feedback-stable mx-3" bordered>
          <thead className="thead-light">
            <tr>
              <th scope="col">{translate('pageElements.cardFeedback.reviewer')}</th>
              <th scope="col">{translate('pageElements.cardFeedback.comments')}</th>
              <th scope="col">
                {translate('pageElements.cardFeedback.actions')}
                <i className="fa fa-info-circle ml-1" id="tooltip611234743" />
                <UncontrolledTooltip delay={0} placement="right" target="tooltip611234743">
                  {translate('pageElements.cardFeedback.actionsToolTipInformation')}
                </UncontrolledTooltip>
              </th>
            </tr>
          </thead>
          <tbody className="list">
            {peerRatings.map((feedback, index) => {
              const selectedFeedbackRating = feedbackOptions.find(
                (option) =>
                  option.value === currentAnswerSafe?.[parseInt(feedback.id, 10)]?.feedbackRating
              );

              if (feedback.id) {
                return (
                  <tr key={feedback.id}>
                    <td data-label={translate('pageElements.cardFeedback.reviewer')}>
                      <p className="name mb-0 text-sm">
                        {`${translate('pageElements.cardFeedback.reviewer')} ${String(index + 1)}`}
                      </p>
                    </td>
                    <td data-label={translate('pageElements.cardFeedback.comments')}>
                      <p className="name mb-0 text-sm span-no-wrap w-100">
                        {feedback.attributes.reviewerAnswerReview.feedback}
                      </p>
                    </td>
                    <td
                      data-label={translate('pageElements.cardFeedback.actions')}
                      style={{ width: '220px' }}
                    >
                      <Select
                        className="feedback-select mt-2 mt-lg-0"
                        placeholder="Select an option"
                        value={selectedFeedbackRating}
                        options={feedbackOptions}
                        onChange={(val: Option) => {
                          rateFeedback(val.value, feedback.id);
                        }}
                      />
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </Table>
      </Row>
    );
  }

  function feedbackSummary(
    reviewerUserAnswers: (CardReviewAnswer | undefined)[],
    userAnswersParam: UserAnswerFeedback[]
  ) {
    const revieweeUserAnswerStatistics =
      userAnswersParam[0].attributes.revieweeUserAnswer?.attributes.statistics;

    if (reviewerUserAnswers[0]?.text === '') {
      return (
        <Col>
          {/* Added this emoji so that no one gets disheartened */}
          &nbsp;
        </Col>
      );
    }

    const userAnswers = feedbacks[pageElementId];

    let aiRating: UserAnswerFeedback | undefined;
    try {
      aiRating = [...userAnswers]
        .reverse()
        .find((item) => item.attributes.reviewerAnswerReview.reviewType === 'ai');
    } catch (err) {
      aiRating = undefined;
    }
    let peerRatings: UserAnswerFeedback[] | undefined;
    try {
      peerRatings = userAnswers.filter(
        (item) => item.attributes.reviewerAnswerReview.reviewType === 'peer'
      );
    } catch (err) {
      peerRatings = undefined;
    }

    const preCheckFailed =
      (aiRating?.attributes.reviewerUserAnswer?.attributes.answer as CardReviewAnswer | undefined)
        ?.preCheckFailed ?? false;

    const feedbackText = aiRating?.attributes.reviewerAnswerReview.feedback;

    const aiRatingFirst = reviewerUserAnswers.length ? reviewerUserAnswers[0] : undefined;
    return (
      <>
        {!preCheckFailed ? (
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Title>{translate('pageElements.cardFeedback.softSkills')}</Title>
                  <ListGroup flush>
                    {options.questions.map((question, index) => {
                      const score =
                        revieweeUserAnswerStatistics?.scores &&
                        revieweeUserAnswerStatistics.scores.length >= index
                          ? revieweeUserAnswerStatistics.scores[index]
                          : 0;
                      const feedback =
                        aiRatingFirst?.feedbacks &&
                        Object.keys(aiRatingFirst.feedbacks).length >= index &&
                        aiRatingFirst.feedbacks[index]?.feedback
                          ? aiRatingFirst.feedbacks[index]?.feedback ?? ''
                          : '';
                      return renderQuestion(question, score, feedback);
                    })}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
        {!preCheckFailed ? (
          <Row>
            <Col>
              {options.competencyQuestions.length > 0 ? (
                <Card>
                  <CardBody>
                    <Title>{translate('pageElements.cardFeedback.competency')}</Title>
                    <ListGroup flush>
                      {options.competencyQuestions.map((question, index) => {
                        const score =
                          revieweeUserAnswerStatistics?.competencyScores &&
                          revieweeUserAnswerStatistics.competencyScores.length >= index
                            ? revieweeUserAnswerStatistics.competencyScores[index]
                            : 0;
                        const feedback =
                          aiRatingFirst?.competencyFeedbacks &&
                          Object.keys(aiRatingFirst.competencyFeedbacks).length >= index &&
                          aiRatingFirst.competencyFeedbacks[index]?.feedback
                            ? aiRatingFirst.competencyFeedbacks[index]?.feedback ?? ''
                            : '';
                        return renderQuestion(question, score, feedback);
                      })}
                    </ListGroup>
                  </CardBody>
                </Card>
              ) : null}
            </Col>
          </Row>
        ) : null}
        {feedbackText ? (
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-2">
                    <h2 className="text-uppercase font-weight-600 mr-2 mt-1">
                      {options.feedbackQuestion}
                    </h2>
                    {preCheckFailed ? (
                      <Badge color="danger">
                        <BiTrendingDown className="mr-1" />
                        <span>Poor Quality</span>
                      </Badge>
                    ) : null}
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: preCheckFailed
                        ? translate('pageElements.cardFeedback.poorFeedback')
                        : feedbackText,
                    }}
                    className=""
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>{feedbackTableSummary(peerRatings)}</Col>
        </Row>
      </>
    );
  }

  const options = peOptions as CardFeedbackOptions;

  const userAnswers: UserAnswerFeedback[] = feedbacks[pageElementId];

  const hasFeedback = pageElementId in feedbacks;
  useEffect(() => {
    setSlideOverData({
      ...slideOverData,
      isOpen: true,
      width: '3xl',
    });
  }, [hasFeedback]);
  if (!hasFeedback) {
    const pageElementAnswer = getDependeeAnswerType(dependees[0]?.dependeeId);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (pageElementAnswer?.type === PAGE_ELEMENT.EXECUTIVE_SUMMARY) {
      const answerElement = getPageElement?.(dependees[0]?.dependeeId);
      return (
        <>
          <ExecutiveSummaryView
            answers={pageElementAnswer.answer as ExecutiveSummaryAnswer}
            options={answerElement?.attributes.options as ExecutiveSummaryOptions}
            preview
          />
          <Row className="mt-6">
            <Col className="text-center">
              <H4>{translate('pageElements.cardFeedback.noFeedbacks')}</H4>
            </Col>
          </Row>
        </>
      );
    }
    if (pageElementAnswer.type === PAGE_ELEMENT.CANVAS) {
      const answerElement = getPageElement?.(dependees[0]?.dependeeId);
      return (
        <>
          <CanvasView
            elements={pageElementAnswer.answer as CanvasAnswer}
            options={answerElement?.attributes.options as CanvasOptions}
          />
          <Row>
            <Col className="text-center">
              <H4>{translate('pageElements.cardFeedback.noFeedbacks')}</H4>
            </Col>
          </Row>
        </>
      );
    }

    if (pageElementAnswer.type === PAGE_ELEMENT.EMAIL_ACTIVITY) {
      const emailAnswer = pageElementAnswer.answer as EmailActivityAnswer;
      return (
        <>
          <div className="emailActivity__container">
            <EmailView
              subject={emailAnswer.subject}
              body={emailAnswer.body}
              senderName={name}
              senderEmail={toEmail}
              senderImage={toImage}
              attachments={[]}
              emailSentAt={emailAnswer.sentAt || ''}
              toMeEmailAddress={emailAnswer.to}
              replyToSimulationEmailId={emailAnswer.simulationEmailId}
            />
          </div>
          <Row className="mt-4">
            <Col className="text-center">
              <H4>
                Wait for feedback, or click Next Stage to proceed. You'll receive an email feedback
                when it is ready to view.
              </H4>
            </Col>
          </Row>
        </>
      );
    }
    if (pageElementAnswer.type === PAGE_ELEMENT.CHAT_ACTIVITY) {
      const chatAnswer = pageElementAnswer.answer as ChatActivityAnswer;
      const answerElement = getPageElement?.(dependees[0]?.dependeeId);
      return (
        <>
          <div
            className="right-side mx-auto"
            style={{
              width: '600px',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              overflow: 'hidden',
              marginBottom: '20px',
            }}
          >
            <ChatBox
              avatar={{
                profileImage: (answerElement?.attributes.options as ChatActivityOptions)
                  .chatActivityData.users[0].profileImage,
                name: (answerElement?.attributes.options as ChatActivityOptions).chatActivityData
                  .users[0].name,
                designation: (answerElement?.attributes.options as ChatActivityOptions)
                  .chatActivityData.users[0].designation,
                id: (answerElement?.attributes.options as ChatActivityOptions).chatActivityData
                  .users[0].id,
                personality: (answerElement?.attributes.options as ChatActivityOptions)
                  .chatActivityData.users[0].personality,
              }}
              messages={chatAnswer.messages}
              disabled
            />
          </div>
          <Row className="mt-4">
            <Col className="text-center">
              <H4>
                Wait for feedback, or click Next Stage to proceed. You'll receive an email feedback
                when it is ready to view.
              </H4>
            </Col>
          </Row>
        </>
      );
    }

    if (pageElementAnswer.type === PAGE_ELEMENT.PRIORITIZATION_MATRIX) {
      const answerElement = getPageElement?.(dependees[0]?.dependeeId);
      return (
        <>
          <Row className="justify-content-center">
            <PrioritizationMatrixView
              preview
              currentAnswer={pageElementAnswer.answer as PrioritizationMatrixAnswer}
              pageElementOptions={answerElement?.attributes.options as PrioritizationMatrixOptions}
            />
          </Row>
          <Row>
            <Col className="text-center">
              <H4>{translate('pageElements.cardFeedback.noFeedbacks')}</H4>
            </Col>
          </Row>
        </>
      );
    }
    if (pageElementAnswer.type === PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP) {
      const answerElement = getPageElement?.(dependees[0]?.dependeeId) ?? null;
      return (
        <>
          <Row className="justify-content-center">
            <CustomerJourneyMapView
              currentAnswer={pageElementAnswer.answer as CustomerJourneyMapAnswer}
              submissionQuestion={
                (answerElement?.attributes.options as CustomerJourneyMapOptions).submissionQuestion
              }
              disabled
            />
          </Row>
          <Row>
            <Col className="text-center">
              <H4>{translate('pageElements.cardFeedback.noFeedbacks')}</H4>
            </Col>
          </Row>
        </>
      );
    }
    if (pageElementAnswer.type === PAGE_ELEMENT.PERSONA_ENTRY) {
      return (
        <>
          <CardPersona persona={pageElementAnswer.answer as PersonaEntryAnswer} />
          <Row>
            <Col className="text-center">
              <h3>{translate('pageElements.cardFeedback.noFeedbacks')}</h3>
            </Col>
          </Row>
        </>
      );
    }
    if (pageElementAnswer.type === PAGE_ELEMENT.SUBMISSION_CARD) {
      const answerElement = getPageElement?.(dependees[0]?.dependeeId);
      const item = pageElementAnswer.answer as SubmissionCardAnswer;
      const { questions, allowAttachment } = answerElement?.attributes
        .options as SubmissionCardOptions;
      return (
        <>
          <Row>
            <Col className="px-4 py-2 pb-4">
              <SubmissionType
                type={item.type}
                images={item.images}
                url={item.link}
                currentAnswer={pageElementAnswer.answer ?? {}}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={{
                size: 6,
                offset: 3,
              }}
            >
              <ReviewSubmissionCard
                data={item}
                questions={questions}
                type={item.type}
                allowAttachment={allowAttachment}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <h3>{translate('pageElements.cardFeedback.noFeedbacks')}</h3>
            </Col>
          </Row>
        </>
      );
    }
    return null;
  } else {
    const userAnswerFeedbacks = userAnswers;

    const revieweeUserAnswer: UserAnswerAnswer | UserAnswerAnswer[] | undefined =
      userAnswerFeedbacks[0]?.attributes?.revieweeUserAnswer?.attributes?.answer;

    const answerElement =
      userAnswerFeedbacks[0]?.attributes?.revieweeUserAnswer?.attributes?.pageElement;
    const reviewerUserAnswers = userAnswerFeedbacks
      .map(
        (x) => x.attributes.reviewerUserAnswer?.attributes.answer as CardReviewAnswer | undefined
      )
      .filter((x) => x?.text);

    // const starsFeedbacks: Record<string, number> = {};
    // const starsCompetencyFeedbacks: Record<string, number> = {};
    // const starsFeedbacksCount: Record<string, number> = {};
    // const starsCompetencyFeedbacksCount: Record<string, number> = {};
    const selectedFeedbacks: Record<string, number> = {};
    // const textsFeedbacks: string[] = [];
    // reviewerUserAnswers.forEach((answer) => {
    //   if (answer.text) {
    //     textsFeedbacks.push(answer.text || '');
    //     answer.feedbacks && Object.keys(answer.feedbacks).forEach((index) => {
    //       starsFeedbacksCount[index] = (starsFeedbacksCount[index] || 0) + 1;
    //       starsFeedbacks[index] =
    //         (starsFeedbacks[index] || 0) +
    //         parseInt(answer?.feedbacks[index].score.toString() || '0', 10);
    //     });
    //     answer.competencyFeedbacks && Object.keys(answer.competencyFeedbacks).forEach((index) => {
    //       starsCompetencyFeedbacksCount[index] = (starsCompetencyFeedbacksCount[index] || 0) + 1;
    //       starsCompetencyFeedbacks[index] =
    //         (starsCompetencyFeedbacks[index] || 0) +
    //         parseInt(answer?.competencyFeedbacks[index].score.toString() || '0', 10);
    //     });
    //   }
    // });
    let indexSelected: number;
    Object.keys(selectedFeedbacks).forEach((index) => {
      const timesSelected = selectedFeedbacks[index];
      const maxTimesSelected = selectedFeedbacks[indexSelected] || 0;
      if (timesSelected > maxTimesSelected) {
        indexSelected = parseInt(index, 10);
      }
    });

    return (
      <>
        {answerElement?.attributes.elementType === PAGE_ELEMENT.CANVAS ? (
          <CanvasView
            elements={revieweeUserAnswer as CanvasAnswer}
            options={answerElement.attributes.options as CanvasOptions}
          />
        ) : null}
        {answerElement?.attributes.elementType === PAGE_ELEMENT.EXECUTIVE_SUMMARY ? (
          <ExecutiveSummaryView
            answers={revieweeUserAnswer as ExecutiveSummaryAnswer}
            options={answerElement.attributes.options as ExecutiveSummaryOptions}
            preview
          />
        ) : null}
        {answerElement?.attributes.elementType === PAGE_ELEMENT.PERSONA_ENTRY ? (
          <CardPersona persona={revieweeUserAnswer as PersonaEntryAnswer} />
        ) : null}
        {answerElement?.attributes.elementType === PAGE_ELEMENT.CHAT_ACTIVITY ? (
          <div
            className="right-side mx-auto"
            style={{
              width: '600px',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              overflow: 'hidden',
              marginBottom: '20px',
            }}
          >
            <ChatBox
              avatar={{
                profileImage: (answerElement.attributes.options as ChatActivityOptions)
                  .chatActivityData.users[0].profileImage,
                name: (answerElement.attributes.options as ChatActivityOptions).chatActivityData
                  .users[0].name,
                designation: (answerElement.attributes.options as ChatActivityOptions)
                  .chatActivityData.users[0].designation,
                id: (answerElement.attributes.options as ChatActivityOptions).chatActivityData
                  .users[0].id,
                personality: (answerElement.attributes.options as ChatActivityOptions)
                  .chatActivityData.users[0].personality,
              }}
              messages={(revieweeUserAnswer as ChatActivityAnswer).messages}
              disabled
            />
          </div>
        ) : null}
        {answerElement?.attributes.elementType === PAGE_ELEMENT.EMAIL_ACTIVITY ? (
          <div className="emailActivity__container">
            <EmailView
              subject={(revieweeUserAnswer as EmailActivityAnswer).subject}
              body={(revieweeUserAnswer as EmailActivityAnswer).body}
              senderName={name}
              senderEmail={toEmail}
              senderImage={toImage}
              attachments={[]}
              emailSentAt={(revieweeUserAnswer as EmailActivityAnswer).sentAt || ''}
              toMeEmailAddress={(revieweeUserAnswer as EmailActivityAnswer).to}
              replyToSimulationEmailId={
                (revieweeUserAnswer as EmailActivityAnswer).simulationEmailId
              }
            />
          </div>
        ) : null}
        {answerElement?.attributes.elementType === PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP ? (
          <CustomerJourneyMapView
            currentAnswer={revieweeUserAnswer as CustomerJourneyMapAnswer}
            submissionQuestion={
              (answerElement.attributes.options as CustomerJourneyMapOptions).submissionQuestion
            }
            disabled
          />
        ) : null}
        {answerElement?.attributes.elementType === PAGE_ELEMENT.SUBMISSION_CARD ? (
          <div>
            <Row>
              <Col className="px-4 py-2 pb-4">
                <SubmissionType
                  type={(revieweeUserAnswer as SubmissionCardAnswer).type}
                  images={(revieweeUserAnswer as SubmissionCardAnswer).images}
                  url={(revieweeUserAnswer as SubmissionCardAnswer).link}
                  currentAnswer={revieweeUserAnswer as SubmissionCardAnswer}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={{
                  size: 6,
                  offset: 3,
                }}
              >
                <ReviewSubmissionCard
                  data={revieweeUserAnswer as SubmissionCardAnswer}
                  questions={(answerElement.attributes.options as SubmissionCardOptions).questions}
                  type={(revieweeUserAnswer as SubmissionCardAnswer).type}
                  allowAttachment={
                    (answerElement.attributes.options as SubmissionCardOptions).allowAttachment
                  }
                />
              </Col>
            </Row>
          </div>
        ) : null}
        <SlideOver slideOverData={slideOverData}>
          <TedoFeedback
            slideOverData={slideOverData}
            setSlideOverData={setSlideOverData}
            feedbackId={pageElementId}
          />
        </SlideOver>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            marginTop: '1.5rem',
            alignItems: 'center',
          }}
        >
          To view feedback, click
          <img
            onClick={() => {
              setSlideOverData({
                ...slideOverData,
                isOpen: true,
                header: 'Tedo Feedback',
                width: '3xl',
              });
            }}
            id="tedoIcon"
            className="projectHeader__icon"
            src={tedoIcon}
            alt="tedoIcon"
          />
        </div>
        <div style={{ display: 'none' }}>{feedbackSummary(reviewerUserAnswers, userAnswers)}</div>
      </>
    );
  }
};

export default CardFeedback;
