import type { ChatActivityAnswer } from '@app/@types/redux/answer';
import { VideoOptions } from '@app/@types/redux/pageElement';
import TempChat, { type ChatItem } from '@components/Chat/TempChat';
import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '@state/redux/store';
import { useEffect, useState } from 'react';
import { PageElementProps } from '..';
import audioIcon from '../../../../assets/icons/audio1.png';
import NotificationModal from './NotificationModal';

const Audio: React.FC<PageElementProps> = (props) => {
  const { pageElement, currentAnswer, answer } = props;
  const { value } = pageElement.attributes.options as VideoOptions;
  const [videoModal, setVideoModal] = useState(false);
  useEffect(() => {
    if (currentAnswer && !(currentAnswer as ChatActivityAnswer).audioFlag) {
      setVideoModal(true);
    }
  }, [currentAnswer]);
  const topicWizardData = useAppSelector(
    (state) =>
      state.projects.currentProject.project.attributes.options.creationOptions.topicWizardData
  );
  const tempMessages: ChatItem[] = [
    {
      id: nanoid(),
      message: value,
      sender: 'assistant',
      timestamp: new Date().toISOString(),
      openedAt: null,
    },
  ];
  return (
    <>
      <TempChat
        pageElement={pageElement}
        answer={props.answer}
        currentAnswer={currentAnswer}
        getDependeeAnswerType={props.getDependeeAnswerType}
        state={props.state}
        tempMessages={tempMessages}
      />
      <NotificationModal
        isOpen={videoModal}
        toggle={() => {
          setVideoModal(false);
        }}
        icon={audioIcon}
        title="Audio Message"
        message={`1 new message ${
          topicWizardData.workplaceScenario?.learnerRoleReportingManager.reportingManager.name
            ? `from ${
                topicWizardData.workplaceScenario.learnerRoleReportingManager.reportingManager
                  .name || ''
              }`
            : ''
        }`}
        onViewMessage={() => {
          answer({
            ...(currentAnswer as ChatActivityAnswer),
            audioFlag: true,
          });
          setVideoModal(false);
        }}
        buttonIcon="https://cartedo.s3.us-east-2.amazonaws.com/web/ab66df4514f4451ccb85.png"
        buttonText="Click to hear message"
      />
    </>
  );
};

export default Audio;
