import { Container } from '@components/UI/Html';
import Layout from '@components/UI/Layout';
import { RenderPageProps, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ViewOnlyResource = () => {
  const resourceToken = useParams().resourceToken;

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
  }, []);

  if (!resourceToken) {
    return null;
  }

  const attachmentUrl = atob(atob(atob(resourceToken)));

  const renderPage = (props: RenderPageProps) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: 'none' }}>{props.textLayer.children}</div>
        {props.annotationLayer.children}
      </>
    );
  };
  return (
    <Layout>
      <Container>
        <div className="py-6">
          <Viewer fileUrl={attachmentUrl} renderPage={renderPage} />
        </div>
      </Container>
    </Layout>
  );
};

export default ViewOnlyResource;
