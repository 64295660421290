import type { PageElement } from '@app/@types/redux/project';
import NotificationModal from '@components/Projects/TakeProject/Elements/NotificationModal';
import { Modal } from '@components/UI/Html';
import logger from '@libs/log';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  fetchSimulationChats,
  updateSimulationChatOpened,
} from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';
import ReactPlayer from 'react-player';
import videoIcon from '../../assets/icons/videoIcon.png';
import ChatBox from './ChatBox';
import ChatMenu from './ChatMenu';
import type { ChatItem } from './TempChat';
import { Chats } from './tempConstants';

interface UserChatItem {
  id: string;
  name: string;
  lastMessage: string;
  lastMessageTime: string;
  profilePicture: string;
  messageStatus: string;
  online: boolean;
  itemType?: string;
}

const SimulationChat = (props: { pageElement?: PageElement }) => {
  const { pageElement } = props;
  const currentProjectId = useAppSelector((state) => state.projects.currentProject.project.id);
  const userProjectSimulationChats = useAppSelector((state) => state.projects.currentProject.chats);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!pageElement) {
      return;
    }
    dispatch(
      fetchSimulationChats({
        projectId: currentProjectId,
        pageElementId: pageElement.id,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        const chats = res.data;
        if (chats.length) {
          setSelectedChat(chats[0].id);
        }
      })
      .catch(() => {
        logger.error('Failed to fetch simulation chats');
      });
  }, [currentProjectId, pageElement?.id]);

  const [selectedChat, setSelectedChat] = useState('');

  const [chats, setChats] = useState<UserChatItem[]>([...Chats]);
  const [searchboxValue, setSearchboxValue] = useState('');

  const [messages, setMessages] = useState<ChatItem[]>([]);
  const [lastUnOpenedMessage, setLastUnOpenedMessage] = useState<ChatItem | null>(null);

  const projectId = useAppSelector((state) => state.projects.currentProject.project.id);

  useEffect(() => {
    if (lastUnOpenedMessage && !lastUnOpenedMessage.openedAt) {
      setNotificationModal(true);
    }
  }, [lastUnOpenedMessage]);

  function handleViewMessage() {
    if (
      !lastUnOpenedMessage?.openedAt &&
      lastUnOpenedMessage?.simulationChatItem?.id &&
      projectId
    ) {
      dispatch(
        updateSimulationChatOpened({
          projectId,
          simulationChatId: lastUnOpenedMessage.simulationChatItem.id,
        })
      )
        .then(() => {
          if (lastUnOpenedMessage.simulationChatItem?.attributes.itemType === 'video') {
            setVideoTutorialModal(true);
          }
        })
        .catch(() => {
          logger.error('Failed to update simulation chat opened');
        });
    }
  }

  const [notificationModal, setNotificationModal] = useState(false);
  const [videoTutorialModal, setVideoTutorialModal] = useState(false);

  useEffect(() => {
    if (userProjectSimulationChats.length) {
      const tempChats = userProjectSimulationChats.map((chat) => {
        return {
          id: chat.id,
          name: chat.attributes.simulationChatItem.attributes.senderName,
          lastMessage: chat.attributes.simulationChatItem.attributes.message,
          lastMessageTime: moment(chat.attributes.simulationChatItem.attributes.updatedAt).format(
            'hh:mm A'
          ),
          profilePicture: chat.attributes.simulationChatItem.attributes.senderImage,
          messageStatus: 'default',
          online: true,
          itemType: chat.attributes.simulationChatItem.attributes.itemType,
          openedAt: chat.attributes.openedAt,
        };
      });
      const tempMessages: ChatItem[] = userProjectSimulationChats.map((chat) => {
        return {
          id: chat.id,
          message: chat.attributes.simulationChatItem.attributes.message,
          sender:
            chat.attributes.simulationChatItem.attributes.senderName === 'user'
              ? 'user'
              : 'assistant',
          timestamp: chat.attributes.simulationChatItem.attributes.updatedAt,
          simulationChatItem: chat.attributes.simulationChatItem,
          openedAt: chat.attributes.openedAt,
        };
      });
      const notOpenedMessages = tempMessages.filter((message) => !message.openedAt);
      if (notOpenedMessages.length) {
        setLastUnOpenedMessage(notOpenedMessages[notOpenedMessages.length - 1]);
      }
      const lastChat = tempChats[tempChats.length - 1];
      setChats([lastChat, ...Chats]);
      setMessages([...tempMessages]);
      setSelectedChat(lastChat.id);
    }
  }, [userProjectSimulationChats]);

  const currentChat = chats.find((chat) => chat.id === selectedChat) ?? chats[0];

  return (
    <div className="chat-container">
      <div className="left-side">
        <div className="chat-search-box-container">
          <div>
            <FaSearch className="chat-search-box-fixed-label" />
          </div>
          <div className="chat-input-wrapper">
            <input
              type="text"
              value={searchboxValue}
              onChange={(e) => {
                setSearchboxValue(e.target.value);
              }}
              placeholder="Search..."
            />
          </div>
        </div>
        {chats.map((chat, index) => (
          <ChatMenu
            key={index}
            chat={chat}
            selected={selectedChat === chat.id}
            handleOnClick={() => {
              setSelectedChat(chat.id);
            }}
          />
        ))}
      </div>
      <div className="right-side">
        <ChatBox
          messages={messages}
          avatar={{
            id: currentChat.id,
            profileImage: currentChat.profilePicture,
            designation: 'Assistant',
            name: currentChat.name,
            personality: 'Assistant',
          }}
          setMessages={setMessages}
          disabled={true}
        />
      </div>
      {lastUnOpenedMessage?.id ? (
        <NotificationModal
          key={lastUnOpenedMessage.id}
          isOpen={notificationModal}
          toggle={() => {
            setNotificationModal(false);
          }}
          imageSrc={lastUnOpenedMessage.simulationChatItem?.attributes.senderImage ?? ''}
          title={`Recorded ${
            lastUnOpenedMessage.simulationChatItem?.attributes.itemType ?? ''
          } message`}
          message={`1 new message from ${
            lastUnOpenedMessage.simulationChatItem?.attributes.senderName ?? ''
          }`}
          onViewMessage={() => {
            handleViewMessage();
          }}
          buttonIcon={
            lastUnOpenedMessage.simulationChatItem?.attributes.itemType === 'video'
              ? 'https://cartedo.s3.us-east-2.amazonaws.com/web/7fca80c527573e84acc0.png'
              : 'https://cartedo.s3.us-east-2.amazonaws.com/web/ab66df4514f4451ccb85.png'
          }
          buttonText={
            lastUnOpenedMessage.simulationChatItem?.attributes.itemType === 'video'
              ? 'Click to watch video'
              : 'Click to hear message'
          }
        />
      ) : null}
      {lastUnOpenedMessage?.simulationChatItem?.attributes.itemType === 'video' ? (
        <Modal
          size="lg"
          isOpen={videoTutorialModal}
          toggle={() => {
            setVideoTutorialModal(false);
          }}
          className=""
          showCloseButton={false}
        >
          <div className="vd_video_container">
            <div className="vd_video_subtitle_container">
              <img className="vd_video_subtitle_image" src={videoIcon} alt="video icon" />
              <div className="chatbox_video_subtitle_text_container">
                <div className="chatbox_video_subtitle_text">Video Message</div>
                <div className="chatbox_video_subtitle_subtext">
                  <div className="chatbox_video_subtitle_recorded_container">
                    <GoDotFill color="#cbcbcb" size={20} />
                    <div className="sdf">RECORDED</div>
                  </div>
                </div>
              </div>
            </div>
            <ReactPlayer
              url={lastUnOpenedMessage.simulationChatItem.attributes.data.url}
              volume={0.8}
              muted={false}
              playing={false}
              controls
              width="100%"
              height={500}
              config={{
                vimeo: {
                  playerOptions: {
                    title: true,
                    byline: false,
                    portrait: false,
                    vimeoLogo: false,
                  },
                },
              }}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default SimulationChat;
