import logger from '@libs/log';
import { logout, useAppDispatch } from '@state/redux/store';
import { formatErrorCode } from '@state/utils/helper';
import { isLoggedIn } from '@utils/helpers/auth';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import problemSolvingImage from '../../../assets/images/placeholders/undraw_problem_solving_ft81.svg';
import { errorToast } from '../../../libs/helper';
import i18n from '../../../libs/i18n/I18n';
import { redirectTo } from '../../../routes/helpers';
import { fetchMe, loginAfterSignUp, verifyToken } from '../../../state/redux/slices/auth.slice';
import Title from '../../Projects/View/Title';
import { Card, CardBody, Col, Container, ImagePlaceholder, Indicator, Row } from '../../UI/Html';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';

function VerifyToken() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const redirect = searchParams.get('redirect');

  const dispatch = useAppDispatch();
  const isSignedIn = isLoggedIn();
  const redirectToLink = useCallback(() => {
    if (redirect) {
      redirectTo(redirect);
      return;
    }
    redirectTo('/workspaces');
  }, [redirect]);
  useEffect(() => {
    if (token) {
      dispatch(verifyToken({ token }))
        .unwrap()
        .then((response) => {
          logout();
          dispatch(
            loginAfterSignUp({
              refreshToken: response.refreshToken,
            })
          )
            .then(() => {
              dispatch(fetchMe())
                .then(() => {
                  redirectToLink();
                })
                .catch(() => {
                  logger.error('Error fetching user data');
                });
            })
            .catch((e: unknown) => {
              errorToast(formatErrorCode('ERCTLNR-CQ7ALMI'));
              logger.error((e as Error).message);
              redirectToLink();
            });
        })
        .catch((error: unknown) => {
          errorToast(formatErrorCode('ERCTLNR-Q8M9MK9'));
          logger.error((error as Error).message);
          redirectToLink();
        });
    }
  }, [dispatch, token, isSignedIn, redirectToLink]);
  return (
    <Layout isFullWidth>
      <Container className="main-container">
        <Row className="justify-content-center vertical-center">
          <Col lg="5" md="7">
            <Card className="shadow">
              <CardBody className="text-center px-lg-5 py-lg-5">
                <Title className="">
                  {i18n.t('auth.magicLinkSignIn.signingIn') as React.ReactNode}
                </Title>
                <div className="mb-4 text-center text-muted">
                  <small>{i18n.t('auth.magicLinkSignIn.pleaseWait') as React.ReactNode}</small>
                </div>
                <Indicator />
                <ImagePlaceholder
                  src={problemSolvingImage}
                  imageStyle={{
                    width: '80%',
                  }}
                  className="my-4"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Footer />
      </Container>
    </Layout>
  );
}

export default VerifyToken;
