import WYSIWYGEditor from '@components/UI/Html/WYSIWYG';
import { DefaultError } from '@libs/constants/errors';
import { errorToast } from '@libs/helper';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import i18n from '../../../libs/i18n/I18n';
import { fetchNote, updateNote } from '../../../state/redux/slices/user.slice';
import { useAppDispatch, useAppSelector } from '../../../state/redux/store';
import { Col, SubmitButton } from '../../UI/Html';

const NoteSidePaneInner = () => {
  const noteId = useAppSelector(
    (state) => state.projects.currentProject.userProject.attributes.userNote.id
  );
  const [loading, setLoading] = useState(true);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [noteState, setNoteState] = useState({
    id: '',
    type: '',
    attributes: { id: '', title: '', text: '', updatedAt: '' },
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchNote(noteId))
      .then((res) => {
        setNoteState(
          res.payload as {
            id: string;
            type: string;
            attributes: { id: string; title: string; text: string; updatedAt: string };
          }
        );
        setLoading(false);
      })
      .catch(() => {
        errorToast(DefaultError);
      });
  }, [noteId]);

  const saveButtonFn = () => {
    setSaveBtnLoading(false);
  };

  return loading ? (
    <Col xs={12} className="m5-5">
      <Skeleton height={30} />
      <div style={{ height: 10 }} />
      <Skeleton height={150} />
    </Col>
  ) : (
    <>
      <WYSIWYGEditor
        value={noteState.attributes.text}
        onBlur={(value) => {
          setNoteState({ ...noteState, attributes: { ...noteState.attributes, text: value } });
        }}
        placeholder="Eg. Share your thoughts here..."
        height="600px"
      />
      <div className="mb-4" />
      <SubmitButton
        color="primary"
        loading={saveBtnLoading}
        onClick={() => {
          setSaveBtnLoading(true);
          dispatch(updateNote({ data: noteState.attributes, saveBtnFn: saveButtonFn })).catch(
            () => {
              errorToast(DefaultError);
            }
          );
        }}
      >
        {i18n.t('notes.saveNoteBtn').toString()}
      </SubmitButton>
    </>
  );
};

export default NoteSidePaneInner;
