import {
  CardReviewAnswer,
  CustomerJourneyMapAnswer,
  FillAnswer,
  IdeaCardAnswer,
  InputStyle1Answer,
  InputStyle4Answer,
  PersonaEntryAnswer,
  PostitCheckboxAnswer,
  PrioritizationMatrixAnswer,
  SubmissionCardAdAnswer,
  SubmissionCardGalleryAnswer,
  SubmissionCardVideoAnswer,
  TextareaStyle1Answer,
  type EmailActivityAnswer,
  type ExecutiveSummaryAnswer,
  type SubmissionCardEmbedAnswer,
} from '@app/@types/redux/answer';
import {
  CardReviewOptions,
  InputOptions,
  PersonaEntryOptions,
  PostitCheckboxOptions,
  PostitOptions,
  SubmissionCardOptions,
  TextareaOptions,
  type ExecutiveSummaryOptions,
} from '@app/@types/redux/pageElement';
import {
  Dependee,
  DependeeValue,
  Evaluation,
  Page,
  PageElement,
  Project,
  ProjectReducer,
  ProjectStage,
  UserAnswerAnswer,
} from '@app/@types/redux/project';
import { GLOBAL_MAX_WORD_LIMIT, PAGE_ELEMENT } from '@libs/constants/constants';
import _ from 'lodash';
import { Store as notificationStore } from 'react-notifications-component';
import ReactPlayer from 'react-player';
import { MARVEL_APP_REGEX, URL_REGEX } from '../../libs/constants/regex';
import i18n, { i18nCText } from '../../libs/i18n/I18n';
import { translate } from '../utils/helper';
import { stripHtml } from '@libs/helper';

export const getReviews = (state: ProjectReducer) => state.reviews;

/* projects */
export const getCurrentProject = (state: ProjectReducer): Project => state.currentProject.project;

/* projects */
export const getCurrentEvaluation = (state: ProjectReducer): Evaluation =>
  state.currentEvaluation.evaluation;

export const getProjectStatus = (state: ProjectReducer) => {
  const { beginDate, endDate, applicationsLeft } = getCurrentProject(state).attributes;
  const userProject = state.currentProject.userProject;
  const begin = new Date(beginDate).getTime();
  const end = new Date(endDate).getTime();
  const today = new Date().getTime();
  const isUserProject = Boolean(userProject.id);
  if (today > end) {
    // challenge finished
    return 'finished';
  }
  if (applicationsLeft <= 0 && !isUserProject) {
    // challenge is full
    return 'filled';
  }
  if (today <= begin) {
    // challenge is not started
    if (!isUserProject) {
      // just can apply
      return 'apply';
    }
    // applied, then need to wait
    return 'soon';
  }

  // challenge started
  if (!isUserProject) {
    // user need to apply
    return 'apply';
  }
  // challenge started and users applied
  // TODO: apply could be rejected
  return 'goto';
};

export const getStage = (state: ProjectReducer, stageId: string): ProjectStage | undefined => {
  const currentProject = getCurrentProject(state);
  const stageIdInt = parseInt(stageId, 10);
  const stage = currentProject.attributes.projectStages[0]
    ? currentProject.attributes.projectStages[0]
    : undefined;
  for (const ps of currentProject.attributes.projectStages) {
    const psId = parseInt(ps.id, 10);
    if (psId === stageIdInt) {
      return ps;
    }
  }
  return stage;
};

export const getPageFromStageId = (
  state: ProjectReducer,
  stageId: string,
  pageId?: string
): Page | undefined => {
  const stage = getStage(state, stageId);
  if (!stage?.id) {
    return undefined;
  }
  const pages = stage.attributes.pages;
  const pageIdInt = pageId;
  const page = pages[0];
  for (const p of pages) {
    const pId = p.id;
    if (pId === pageIdInt) {
      return p;
    }
  }
  return page;
};

export const getPageFromEvaluation = (evaluation: Evaluation, pageId: string): Page | undefined => {
  if (!evaluation.id) {
    return undefined;
  }
  const pages = evaluation.attributes.pages;
  const pageIdInt = parseInt(pageId, 10);
  let page;
  if (!pageId) {
    // eslint-disable-next-line prefer-destructuring
    page = pages[0];
  }
  for (const p of pages) {
    const pId = parseInt((p as { id: string }).id, 10);
    if (pId === pageIdInt) {
      return p;
    }
  }
  return page;
};

export const getPage = (state: ProjectReducer, pageId: string) => {
  const project = getCurrentProject(state);
  if (!project.attributes.projectStages.length) {
    return undefined;
  }
  for (const ps of project.attributes.projectStages) {
    const page = getPageFromStageId(state, ps.id, pageId);
    if (page) {
      return page;
    }
  }
  return undefined;
};

export const getCurrentStage = (state: ProjectReducer) => getStage(state, state.currentStageId);

export const getActiveStage = (state: ProjectReducer) => getStage(state, state.activeStageId);

export const getWorkingStage = (state: ProjectReducer): ProjectStage | undefined => {
  let stage;
  const activeStage = getActiveStage(state);
  if (activeStage?.id) {
    stage = activeStage;
  } else {
    stage = getCurrentStage(state);
  }
  return stage;
};

export const pageBelongsToProject = (pageId: string, project: Project): boolean => {
  const pageIdInt = parseInt(pageId, 10);
  if (!project.attributes.projectStages.length) return false;
  for (const stage of project.attributes.projectStages) {
    for (const page of stage.attributes.pages) {
      const pIdInt = parseInt(page.id, 10);
      if (pIdInt === pageIdInt) {
        return true;
      }
    }
  }
  return false;
};

export const pageBelongsToEvaluation = (pageId: string, evaluation: Evaluation) => {
  if (!evaluation.attributes.pages.length) return false;
  const pageIdInt = parseInt(pageId, 10);
  for (const page of evaluation.attributes.pages) {
    const pIdInt = parseInt(page.id, 10);
    if (pIdInt === pageIdInt) {
      return true;
    }
  }
  return false;
};

export const getCurrentPage = (state: ProjectReducer) => {
  const { currentPageId } = state;
  const stage = getWorkingStage(state);
  if (!stage) {
    return null;
  }
  const page = getPageFromStageId(state, stage.id, currentPageId);
  return page;
};
export const getCurrentEvaluationPage = (state: ProjectReducer) => {
  const { currentEvaluationPageId } = state;
  const evaluation = getCurrentEvaluation(state);
  const page = getPageFromEvaluation(evaluation, currentEvaluationPageId);
  return page;
};

export const getAnswers = (state: ProjectReducer) => state.answers;

export const getEvaluationAnswers = (state: ProjectReducer) => state.evaluationAnswers;

export const getRestrictionStateSelector = (state: ProjectReducer) => state.restrictions;

export const getPageElementAnswerAndType = (state: ProjectReducer, peId: string) =>
  (
    state.answers[peId] as
      | {
          type: PAGE_ELEMENT;
          answer: UserAnswerAnswer;
        }
      | undefined
  )?.type
    ? state.answers[peId]
    : undefined;

export const getEvaluationPageElementAnswerAndType = (state: ProjectReducer, peId: string) =>
  (
    state.evaluationAnswers[peId] as
      | {
          type: string;
          answer: UserAnswerAnswer;
        }
      | undefined
  )?.type
    ? state.evaluationAnswers[peId]
    : undefined;

export const getPageElementById = (
  state: ProjectReducer,
  peId: string
): PageElement | undefined => {
  const project = getCurrentProject(state);
  if (!project.attributes.projectStages.length) {
    return undefined;
  }
  for (const stage of project.attributes.projectStages) {
    for (const page of stage.attributes.pages) {
      for (const pageElement of page.attributes.pageElements) {
        if (String(pageElement.id) === String(peId)) {
          return pageElement;
        }
      }
    }
  }
  return undefined;
};

export const isLastStage = (state: ProjectReducer) => {
  const currentStage = getWorkingStage(state);
  if (!currentStage?.id) {
    return false;
  }
  const project = getCurrentProject(state);
  if (!project.attributes.projectStages.length) {
    return false;
  }
  const nextStages = project.attributes.projectStages.filter(
    (stage: { attributes: { position: number } }) =>
      stage.attributes.position > currentStage.attributes.position
  );
  if (nextStages.length > 0) {
    // There's a next stage and therefore this is not the last stage
    return false;
  }
  return true;
};

export const isLastPage = (state: ProjectReducer) => {
  const currentPage = getCurrentPage(state);
  if (!currentPage) {
    // There's no page selected yet
    return false;
  }
  const currentStage = getWorkingStage(state);

  if (!currentStage?.id) {
    return false;
  }
  if (!isLastStage(state)) {
    // There's a next stage and therefore this is not the last stage
    return false;
  }
  if (!currentStage.attributes.pages.length) {
    return false;
  }
  const nextPages = currentStage.attributes.pages.filter(
    (page: { attributes: { position: number } }) =>
      page.attributes.position > currentPage.attributes.position
  );
  if (nextPages.length > 0) {
    // There's a next page and therefore this is not the last page
    return false;
  }
  return true;
};

export const isLastEvaluationPage = (state: ProjectReducer) => {
  const currentPage = getCurrentEvaluationPage(state);
  const evaluation = getCurrentEvaluation(state);
  if (!currentPage) {
    // There's no page selected yet
    return false;
  }
  const nextPages = evaluation.attributes.pages.filter(
    (page: { attributes: { position: number } }) =>
      page.attributes.position > currentPage.attributes.position
  );
  if (nextPages.length > 0) {
    // There's a next page and therefore this is not the last page
    return false;
  }
  return true;
};

export const getAutoPopupModal = (state: ProjectReducer, pageId: string): string => {
  const pageIdInt = parseInt(pageId, 10);
  const project = getCurrentProject(state);
  for (const stage of project.attributes.projectStages) {
    for (const page of stage.attributes.pages) {
      const pIdInt = parseInt(page.id, 10);
      if (pIdInt === pageIdInt) {
        return page.attributes.options.autoPopup ? page.attributes.options.autoPopup : '';
      }
    }
  }
  return '';
};

const pageElementNeedAnswer = (pageElement: PageElement) =>
  [
    PAGE_ELEMENT.INPUT,
    PAGE_ELEMENT.RADIO,
    PAGE_ELEMENT.TEXTAREA,
    PAGE_ELEMENT.USER_SELECT,
    PAGE_ELEMENT.UPLOAD_FILE,
    PAGE_ELEMENT.CHECKBOX,
    PAGE_ELEMENT.IMAGE_CHECKBOX,
    PAGE_ELEMENT.FILL,
    PAGE_ELEMENT.IMAGE_RADIO,
    PAGE_ELEMENT.POSTIT,
    PAGE_ELEMENT.POSTIT_CHECKBOX,
    PAGE_ELEMENT.ADD_ENTRY,
    PAGE_ELEMENT.CARD_REVIEW,
    PAGE_ELEMENT.PERSONA_ENTRY,
    PAGE_ELEMENT.SUBMISSION_CARD,
    PAGE_ELEMENT.IDEA_CARD,
    PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP,
    PAGE_ELEMENT.PRIORITIZATION_MATRIX,
    PAGE_ELEMENT.EMAIL_ACTIVITY,
    PAGE_ELEMENT.EXECUTIVE_SUMMARY,
    PAGE_ELEMENT.ESSAY,
    PAGE_ELEMENT.CANVAS,
  ].includes(pageElement.attributes.elementType) && pageElement.attributes.required;

export const isPageElementAnswered = ({
  pageElementAnswer: paramPageElementAnswer,
  pageElement,
}: {
  pageElementAnswer: UserAnswerAnswer | undefined;
  pageElement: PageElement;
}) => {
  let errorMessage = translate('pageElements.errorMessages.defaultError');
  const answerKeys = Object.keys(paramPageElementAnswer ?? {});
  const {
    attributes: { elementType },
  } = pageElement;
  if ([PAGE_ELEMENT.TEXTAREA].includes(elementType)) {
    const pageElementAnswer = paramPageElementAnswer as TextareaStyle1Answer | undefined;
    const options = pageElement.attributes.options as TextareaOptions;
    const maxLength = options.maxLength;
    if ((pageElementAnswer?.value.length ?? 0) > maxLength) {
      errorMessage = translate('pageElements.errorMessages.maxLength', {
        maxLengthAllowed: maxLength,
      });
      return errorMessage;
    }
    if (!pageElementAnswer?.value) {
      errorMessage = translate('pageElements.errorMessages.error1');
      return errorMessage;
    }
  }
  if ([PAGE_ELEMENT.EMAIL_ACTIVITY].includes(elementType)) {
    const pageElementAnswer = paramPageElementAnswer as EmailActivityAnswer | undefined;
    if (!pageElementAnswer?.body) {
      errorMessage = 'Please reply to the email';
      return errorMessage;
    }
  }
  if ([PAGE_ELEMENT.PRIORITIZATION_MATRIX].includes(elementType)) {
    const pageElementAnswer = paramPageElementAnswer as PrioritizationMatrixAnswer;

    if (!pageElementAnswer.mapData.every((i) => i.filter((g) => g).length)) {
      errorMessage = translate('pageElements.errorMessages.error1');
      return errorMessage;
    }
    const ranks = pageElementAnswer.ranks;
    const uniqueRanks = [...new Set(ranks)];
    if (ranks.length !== uniqueRanks.length) {
      errorMessage = 'Please change the priority of equal items';
      return errorMessage;
    }
  }
  if ([PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP].includes(elementType)) {
    const pageElementAnswer = paramPageElementAnswer as CustomerJourneyMapAnswer;
    errorMessage = 'Please fill in all the required fields';
    for (const rows of pageElementAnswer.map ?? []) {
      for (const col of rows) {
        if (col === '') {
          return errorMessage;
        }
      }
    }
  }

  if ([PAGE_ELEMENT.INPUT].includes(elementType)) {
    const { type, maxLength } = pageElement.attributes.options as InputOptions;
    if (type === 'style_4') {
      const pageElementAnswer = paramPageElementAnswer as InputStyle4Answer;
      for (const answer of pageElementAnswer.values) {
        if (!answer.value) {
          errorMessage = translate('pageElements.errorMessages.defaultError');
          return errorMessage;
        }
      }
    } else {
      const pageElementAnswer = paramPageElementAnswer as InputStyle1Answer;
      if (pageElementAnswer.answer.length > maxLength) {
        errorMessage = translate('pageElements.errorMessages.maxLength', {
          maxLengthAllowed: maxLength,
        });
        return errorMessage;
      }
      if (!pageElementAnswer.answer) {
        errorMessage = translate('pageElements.errorMessages.error1');
        return errorMessage;
      }
    }
  }
  if ([PAGE_ELEMENT.RADIO, 'image_radio'].includes(elementType) && answerKeys.length === 0) {
    errorMessage = translate('pageElements.errorMessages.error2');
    return errorMessage;
  }
  if (elementType === PAGE_ELEMENT.POSTIT) {
    const { type, min } = pageElement.attributes.options as PostitOptions;
    if (type === 'flip_example') {
      return 'answered';
    }
    if (min && answerKeys.length < min) {
      errorMessage = translate('pageElements.errorMessages.error3', {
        min,
      });
      return errorMessage;
    }
  }
  if ([PAGE_ELEMENT.POSTIT_CHECKBOX, PAGE_ELEMENT.ADD_ENTRY].includes(elementType)) {
    const { max, min } = pageElement.attributes.options as PostitCheckboxOptions;
    const pageElementAnswer = paramPageElementAnswer as PostitCheckboxAnswer;
    if (Object.keys(pageElementAnswer).length > 0) {
      if (min && max && (answerKeys.length < min || answerKeys.length > max)) {
        errorMessage = translate('pageElements.errorMessages.error4', {
          max,
        });
        return errorMessage;
      }
    } else {
      errorMessage = translate('pageElements.errorMessages.error4', {
        max,
      });
      return errorMessage;
    }
  }
  if (elementType === PAGE_ELEMENT.IDEA_CARD) {
    const pageElementAnswer = paramPageElementAnswer as IdeaCardAnswer;
    for (const key of answerKeys) {
      const { title, image, description } = pageElementAnswer[key];
      if (!title) {
        errorMessage = translate('pageElements.errorMessages.error7');
        return errorMessage;
      }
      if (!description) {
        errorMessage = translate('pageElements.errorMessages.error8');
        return errorMessage;
      }
      if (!image.regular) {
        errorMessage = translate('pageElements.errorMessages.error9');
        return errorMessage;
      }
    }
  }
  if (elementType === PAGE_ELEMENT.PERSONA_ENTRY) {
    const pageElementAnswer = paramPageElementAnswer as PersonaEntryAnswer;
    const options = pageElement.attributes.options as PersonaEntryOptions;
    if (Object.keys(pageElementAnswer).length > 0) {
      if (!pageElementAnswer.image?.regular) {
        errorMessage = 'Please add a photo';
        return errorMessage;
      }
      if (!pageElementAnswer.title) {
        errorMessage = 'Please add a title';
        return errorMessage;
      }
      if (!pageElementAnswer.description) {
        errorMessage = 'Please add a description';
        return errorMessage;
      }
      if (options.inputFieldsVisible) {
        for (const field of pageElementAnswer.inputFields) {
          if (!field.fieldValue) {
            errorMessage = translate('pageElements.errorMessages.error5', {
              key: i18nCText(field.fieldLabel),
            });
            return errorMessage;
          }
        }
      }
      if (options.longTextareaFieldsVisible) {
        for (const field of pageElementAnswer.longTextareaFields) {
          if (!field.fieldValue) {
            errorMessage = translate('pageElements.errorMessages.error5', {
              key: i18nCText(field.fieldLabel),
            });
            return errorMessage;
          }
        }
      }
      if (options.textareaFieldsVisible) {
        for (const field of pageElementAnswer.textareaFields) {
          if (!field.fieldValue) {
            errorMessage = translate('pageElements.errorMessages.error5', {
              key: i18nCText(field.fieldLabel),
            });
            return errorMessage;
          }
        }
      }
    } else {
      return errorMessage;
    }
  }
  if (elementType === PAGE_ELEMENT.FILL) {
    const pageElementAnswer = paramPageElementAnswer as FillAnswer;
    if (pageElementAnswer.value.length === 0) {
      for (const key of answerKeys) {
        if (!pageElementAnswer[key]) {
          errorMessage = translate('pageElements.errorMessages.error5', {
            key: i18nCText(key),
          });
          return errorMessage;
        }
      }
    } else {
      return errorMessage;
    }
  }
  if ([PAGE_ELEMENT.EXECUTIVE_SUMMARY, PAGE_ELEMENT.ESSAY].includes(elementType)) {
    const pageElementAnswer = paramPageElementAnswer as ExecutiveSummaryAnswer;
    const options = pageElement.attributes.options as ExecutiveSummaryOptions;
    if (Object.keys(pageElementAnswer).length !== options.questions.length) {
      errorMessage = translate('Answer all the questions');
      return errorMessage;
    }
    if (
      Object.keys(pageElementAnswer).sort().join() !==
      options.questions
        .map((q) => q.id)
        .sort()
        .join()
    ) {
      errorMessage = translate('Please answer all the questions');
      return errorMessage;
    }
    if (Object.values(pageElementAnswer).some((a) => !a.answer)) {
      errorMessage = translate('Please answer all the questions');
      return errorMessage;
    }
    if (
      Object.entries(pageElementAnswer).some(([key, value]) => {
        const ques = options.questions.find((q) => q.id === key);
        const answerWordCount = stripHtml(value.answer.replace(/\s+/g, '')).length;
        return (
          ques &&
          answerWordCount > parseInt(ques.maxWordCount ?? GLOBAL_MAX_WORD_LIMIT.toString()) * 5
        );
      })
    ) {
      errorMessage = translate('Please check the word count');
      return errorMessage;
    }
  }
  if (elementType === PAGE_ELEMENT.SUBMISSION_CARD) {
    const pageElementAnswer = paramPageElementAnswer as
      | SubmissionCardGalleryAnswer
      | SubmissionCardAdAnswer
      | SubmissionCardVideoAnswer
      | SubmissionCardEmbedAnswer;
    const { questions: optionsQuestions } = pageElement.attributes.options as SubmissionCardOptions;
    if (!pageElementAnswer.type) {
      return errorMessage;
    }
    if (answerKeys.length === 0) {
      errorMessage = translate('pageElements.errorMessages.error6');
      return errorMessage;
    }
    if (['embed'].includes(pageElementAnswer.type)) {
      const pageElementEmbedAnswer = pageElementAnswer as SubmissionCardEmbedAnswer;
      if (!pageElementEmbedAnswer.embedCode) {
        errorMessage = translate('pageElements.submissionCard.embed');
        return errorMessage;
      }
    }
    if (['ad'].includes(pageElementAnswer.type) && !pageElementAnswer.title) {
      errorMessage = translate('pageElements.submissionCard.title');
      return errorMessage;
    }
    if (['ad'].includes(pageElementAnswer.type)) {
      const pageElementAdAnswer = pageElementAnswer as SubmissionCardAdAnswer;
      if (!pageElementAdAnswer.description) {
        errorMessage = translate('pageElements.submissionCard.description');
        return errorMessage;
      }
      if (!pageElementAdAnswer.callToAction) {
        errorMessage = translate('pageElements.submissionCard.callToAction');
        return errorMessage;
      }
      if (!pageElementAdAnswer.headline) {
        errorMessage = translate('pageElements.submissionCard.headline');
        return errorMessage;
      }
      if (!pageElementAdAnswer.displayLink) {
        errorMessage = translate('pageElements.submissionCard.displayLink');
        return errorMessage;
      }
      if (!pageElementAdAnswer.image.regular) {
        errorMessage = translate('pageElements.submissionCard.image');
        return errorMessage;
      }
      if (!pageElementAdAnswer.profileImage.regular) {
        errorMessage = translate('pageElements.submissionCard.profileImage');
        return errorMessage;
      }
    }
    if (['gallery', 'images'].includes(pageElementAnswer.type)) {
      if ((pageElementAnswer as SubmissionCardGalleryAnswer).images.length === 0) {
        errorMessage = 'Please add images to your final prototype';
        return errorMessage;
      }
    }
    if (['video', 'app'].includes(pageElementAnswer.type)) {
      const pageElementAnswer = paramPageElementAnswer as SubmissionCardVideoAnswer;
      if (!pageElementAnswer.link) {
        errorMessage = translate('pageElements.errorMessages.error5', {
          key: 'link',
        });
        return errorMessage;
      }
      if (pageElementAnswer.type === 'app' && !MARVEL_APP_REGEX.test(pageElementAnswer.link)) {
        errorMessage = 'Please enter a valid Marvel App url';
        return errorMessage;
      }
      if (
        pageElementAnswer.type === 'video' &&
        !URL_REGEX.test(pageElementAnswer.link) &&
        !ReactPlayer.canPlay(pageElementAnswer.link)
      ) {
        errorMessage = 'Please enter a valid video url';
        return errorMessage;
      }
    }
    if (answerKeys.includes('questions')) {
      const { questions } = pageElementAnswer;
      if (Object.keys(questions).length === optionsQuestions.length) {
        for (const questionKey of Object.keys(questions)) {
          if (!questions[questionKey].value) {
            errorMessage = translate('pageElements.errorMessages.error5', {
              key: questions[questionKey].question
                ? i18nCText(questions[questionKey].question)
                : translate('pageElements.elementColumns.link'),
            });
            return errorMessage;
          }
        }
      } else {
        errorMessage = translate('pageElements.errorMessages.error6');
        return errorMessage;
      }
    } else {
      errorMessage = translate('pageElements.errorMessages.error6');
      return errorMessage;
    }
  }
  if (elementType === PAGE_ELEMENT.CARD_REVIEW) {
    const {
      questions: pageElementQuestions = [],
      competencyQuestions: pageElementCompetencyQuestions = [],
    } = pageElement.attributes.options as CardReviewOptions;
    const pageElementAnswer = paramPageElementAnswer as CardReviewAnswer;
    const { feedbacks, competencyFeedbacks } = pageElementAnswer;
    if (
      (pageElementQuestions.length > 0 &&
        feedbacks &&
        Object.keys(feedbacks).length !== pageElementQuestions.length) ??
      (pageElementCompetencyQuestions.length > 0 &&
        competencyFeedbacks &&
        Object.keys(competencyFeedbacks).length !== pageElementCompetencyQuestions.length)
    ) {
      errorMessage = translate('pageElements.errorMessages.error6');
      return errorMessage;
    }
  }
  const isAnswered = answerKeys.length !== 0;
  if (!isAnswered) {
    return errorMessage;
  }
  return 'answered';
};

export const canGoToPage = (state: ProjectReducer, pageId: string, mode = 'nextPage') => {
  const answers = getAnswers(state); // todo Rachit - check if this is correct
  const project = getCurrentProject(state);
  const currentStage = getCurrentStage(state);
  if (!project.attributes.projectStages.length) {
    return undefined;
  }
  const pageIdInt = parseInt(pageId, 10);
  const currentStageId = parseInt(String(currentStage?.id), 10);
  for (const stage of project.attributes.projectStages) {
    const sIdInt = parseInt(stage.id, 10);
    for (const page of stage.attributes.pages) {
      const pIdInt = parseInt(page.id, 10);
      if (pIdInt === pageIdInt && mode === 'nextPage') {
        return true;
      }
      if (sIdInt === currentStageId) {
        for (const pageElement of page.attributes.pageElements) {
          if (pageElementNeedAnswer(pageElement)) {
            const {
              attributes: { id: pageElementId },
            } = pageElement;
            const pageElementAnswer = answers[pageElementId]?.answer;
            const res = isPageElementAnswered({
              pageElementAnswer,
              pageElement,
            });
            if (res !== 'answered') {
              return res;
            }
          }
        }
      }
      if (sIdInt === currentStageId && page.attributes.pageElements.length === 1) {
        if (page.attributes.pageType === 'Other' && page.attributes.options.type === 'quiz') {
          if (!state.currentEvaluation.userEvaluation.attributes.results) {
            return 'Please take the quiz';
          }
        }
      }
      if (pIdInt === pageIdInt && mode === 'submit') {
        return true;
      }
    }
  }
  return false;
};

export const canGoToEvaluationPage = (state: ProjectReducer, pageId: string) => {
  const answers = getEvaluationAnswers(state);
  const evaluation = getCurrentEvaluation(state);
  if (!evaluation.attributes.pages.length) {
    return undefined;
  }
  const pageIdInt = parseInt(pageId, 10);
  for (const page of evaluation.attributes.pages) {
    const pIdInt = parseInt(page.id, 10);
    if (pIdInt === pageIdInt) {
      return true;
    }
    for (const pageElement of page.attributes.pageElements) {
      const answer = answers[pageElement.id]?.answer;
      if (pageElementNeedAnswer(pageElement)) {
        const isAnswered = Object.keys(answer ?? {}).length !== 0;
        if (!isAnswered) {
          return false;
        }
      }
    }
  }
  return false;
};

export const getFeedback = (state: ProjectReducer) => state.feedbacks;

export const getLastStageLastPage = (state: ProjectReducer, projectId: string) => {
  const project = _.find(state.projects, { id: projectId });
  if (project) {
    const projectStages = project.attributes.projectStages;
    if (projectStages.length > 0) {
      const lastStage = projectStages[projectStages.length - 1];
      const pages = lastStage.attributes.pages;
      if (pages.length > 0) {
        const lastPage = pages[pages.length - 1];
        return {
          lastPageId: lastPage.id,
          lastStageId: lastStage.id,
        };
      }
    }
  }
  return {
    lastPageId: 1,
    lastStageId: 1,
  };
};

export function notAnsweredAlert(message?: string) {
  notificationStore.addNotification({
    message: message ?? i18n.t('pageElements.errorMessages.defaultError'),
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
}

export function parseDependeeValue(
  {
    type,
    answer,
  }: {
    type: PAGE_ELEMENT;
    answer: UserAnswerAnswer;
  },
  dependeeKey: string
):
  | PostitCheckboxAnswer[keyof PostitCheckboxAnswer][]
  | IdeaCardAnswer[keyof IdeaCardAnswer][]
  | FillAnswer
  | UserAnswerAnswer
  | (PersonaEntryAnswer & { elementType: PAGE_ELEMENT })
  | string[]
  | null {
  if (!type) {
    return null;
  }
  if ([PAGE_ELEMENT.INPUT, PAGE_ELEMENT.IMAGE_CHECKBOX].includes(type)) {
    return answer;
  }
  if (
    [
      PAGE_ELEMENT.RADIO,
      PAGE_ELEMENT.TEXTAREA,
      PAGE_ELEMENT.USER_SELECT,
      PAGE_ELEMENT.UPLOAD_FILE,
    ].includes(type)
  ) {
    return (answer as TextareaStyle1Answer).value;
  }
  if ([PAGE_ELEMENT.IDEA_CARD, 'card'].includes(type)) {
    return Object.keys(answer as IdeaCardAnswer).map((index) => (answer as IdeaCardAnswer)[index]);
  }
  if ([PAGE_ELEMENT.FILL].includes(type)) {
    return dependeeKey ? (answer as FillAnswer)[dependeeKey] : (answer as FillAnswer);
  }
  if (
    [
      'image_radio',
      PAGE_ELEMENT.SUBMISSION_CARD,
      PAGE_ELEMENT.MESSAGE,
      'hmw_random',
      'canvas',
      'ai_response',
      'others',
      PAGE_ELEMENT.EXECUTIVE_SUMMARY,
    ].includes(type)
  ) {
    return answer;
  }
  if ([PAGE_ELEMENT.PERSONA_ENTRY].includes(type)) {
    return {
      elementType: type,
      ...(answer as PersonaEntryAnswer),
    };
  }
  if (
    [
      PAGE_ELEMENT.CHECKBOX,
      PAGE_ELEMENT.POSTIT,
      PAGE_ELEMENT.POSTIT_CHECKBOX,
      'input_list',
    ].includes(type)
  ) {
    return Object.keys(answer as PostitCheckboxAnswer).map(
      (index) => (answer as PostitCheckboxAnswer)[index]
    );
  }
  if ([PAGE_ELEMENT.PRIORITIZATION_MATRIX].includes(type)) {
    return answer;
  }
  return null;
}

export function getRestrictionState(state: ProjectReducer) {
  const restrictions = getRestrictionStateSelector(state);
  return restrictions;
}

export function getPageElementDisabledState(rootState: ProjectReducer, pageElementId: string) {
  const restrictions =
    getRestrictionState(rootState).length > 0 ? getRestrictionState(rootState) : [];
  let state = false;
  restrictions.forEach((restriction) => {
    restriction.pages.forEach((page) => {
      page.pageElements.forEach((pe) => {
        if (pe.pageElementId === pageElementId) {
          state = pe.disabled;
        }
      });
    });
  });
  return state;
}

export function getDependeeValues(
  dependees: Dependee[],
  values: unknown[],
  state: ProjectReducer
): DependeeValue[] | unknown[] | UserAnswerAnswer {
  const dependeeValues: DependeeValue[] = [];
  dependees.forEach((dependee) => {
    const pageElementAnswerAndType =
      dependee.dependeeId && getPageElementAnswerAndType(state, dependee.dependeeId);

    const dependeeValue =
      dependee.dependeeId &&
      pageElementAnswerAndType &&
      parseDependeeValue(
        pageElementAnswerAndType as {
          type: PAGE_ELEMENT;
          answer: UserAnswerAnswer;
        },
        dependee.dependeeKey
      );
    const tempDependeePageElement = getPageElementById(state, dependee.dependeeId);
    if (tempDependeePageElement === undefined) {
      return;
    }
    const dependeePageElement = {
      elementType: tempDependeePageElement.attributes.elementType,
      options: tempDependeePageElement.attributes.options,
    };
    if (Array.isArray(dependeeValue)) {
      dependeeValue.forEach((item) => {
        let n = {
          elementType: (
            getPageElementAnswerAndType(state, dependee.dependeeId) as {
              type: PAGE_ELEMENT;
              answer: UserAnswerAnswer;
            }
          ).type,
          dependeePageElement,
          dependeeKey: dependee.dependeeKey,
        };
        if (typeof item === 'object') n = { ...n, ...item };
        dependeeValues.push(n);
      });
    } else {
      dependeeValues.push({
        answer: dependeeValue,
        elementType: (
          getPageElementAnswerAndType(state, dependee.dependeeId) as {
            type: PAGE_ELEMENT;
            answer: UserAnswerAnswer;
          }
        ).type,
        dependeePageElement,
        dependeeKey: dependee.dependeeKey,
      });
    }
  });
  return dependeeValues.length ? dependeeValues : values;
}

export function canSubmit(state: ProjectReducer) {
  const { currentPageId } = state;
  const available = canGoToPage(state, currentPageId, 'submit');
  if (typeof available === 'string') {
    notAnsweredAlert(available);
    return false;
  }
  return true;
}
