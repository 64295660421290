import { SubmissionCardAnswer, SubmissionCardAttachment } from '@app/@types/redux/answer';
import { redirectToExternal } from '@app/routes/helpers';
import { SUBMISSION_TYPES } from '../../../../libs/constants';
import { i18nCText } from '../../../../libs/i18n/I18n';
import { translate } from '../../../../state/utils/helper';
import {
  A,
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Text,
} from '../../../UI/Html';

interface ReviewSubmissionCardProps {
  data: SubmissionCardAnswer;
  type: string;
  questions: string[];
  allowAttachment: boolean;
}

const ReviewSubmissionCard: React.FC<ReviewSubmissionCardProps> = (props) => {
  const listAttachments = (attachment: SubmissionCardAttachment, index: number) => (
    <ListGroupItem className="addEntryAttachmentListItem" key={index}>
      <Row className="align-items-center">
        <Col className="col">
          <Text className="text-muted">{decodeURI(attachment.url.split('/').pop() ?? '')}</Text>
        </Col>
        <Col className="col-auto">
          <Button
            color="primary"
            size="sm"
            type="button"
            onClick={() => {
              redirectToExternal(attachment.url);
            }}
          >
            <i className="fas fa-eye" />
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );

  function renderQuestionAnswer(question: string, index: number) {
    const { data } = props;
    const questionsAnswers = data.questions;
    return (
      <ListGroupItem
        className="checklist-entry flex-column align-items-start py-2 px-2"
        key={index}
      >
        <div className="checklist-item checklist-item">
          <div className="checklist-info">
            <h4 className="checklist-title mb-0">
              <span
                dangerouslySetInnerHTML={{
                  __html: `${String(index + 1)}. ${i18nCText(question)}`,
                }}
              />
            </h4>
            <small>{questionsAnswers[index].value}</small>
          </div>
        </div>
      </ListGroupItem>
    );
  }

  const { questions, data, type: submissionType, allowAttachment } = props;
  const { link } = data;
  const attachments = data.attachments;
  const currentSubmissionType = SUBMISSION_TYPES.find((item) => item.type === submissionType);
  if (!currentSubmissionType) return null;
  return (
    <Card>
      <CardBody>
        <ListGroup flush>
          {/* <ListGroupItem className="checklist-entry flex-column align-items-start py-2 px-2">
            <div className="checklist-item checklist-item">
              <div className="checklist-info">
                <h4 className="checklist-title mb-0">{currentSubmissionType.title.label}</h4>
                <small>{title || '...'}</small>
              </div>
            </div>
          </ListGroupItem> */}
          {link && (
            <ListGroupItem className="checklist-entry flex-column align-items-start py-2 px-2">
              <div className="checklist-item checklist-item">
                <div className="checklist-info">
                  <h4 className="checklist-title mb-0">{currentSubmissionType.link?.label}</h4>
                  <small>
                    <A href={link} external target="_blank">
                      {link}
                    </A>
                  </small>
                </div>
              </div>
            </ListGroupItem>
          )}
          {questions.map((question, index) => renderQuestionAnswer(question, index))}
          {allowAttachment && attachments.length && attachments.length > 0 ? (
            <Row>
              <Col>
                <ListGroup>
                  <ListGroupItem className="checklist-entry flex-column align-items-start py-2 px-2">
                    <div className="checklist-item checklist-item">
                      <div className="checklist-info flex-fill">
                        <h4 className="checklist-title mb-0">
                          {translate('pageElements.elementColumns.attachments')}
                        </h4>
                        {attachments.map((item, index) => listAttachments(item, index))}
                      </div>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          ) : null}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default ReviewSubmissionCard;
