import env from '@config/env';
import logger from '@libs/log';
import ReactGA from 'react-ga4';
import { router } from './routes';

ReactGA.initialize(env.GA_TRACKING_ID);

const redirectTo = (path: string) => {
  router
    .navigate(path)
    .then(() => {
      ReactGA.send({ hitType: 'pageview', page: path, title: document.title });
    })
    .catch(() => {
      logger.error('Failed to redirect to: ' + path);
    });
  return null;
};

const redirectToExternal = (path: string, target = '_blank') => {
  const a = document.createElement('a');
  a.href = path;
  a.target = target;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export { redirectTo, redirectToExternal };
