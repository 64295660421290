import env from '@config/env';

/* eslint-disable no-console */
const logger = {
  info: (message: unknown) => {
    if (env.DEV) console.info(`INFO: ${String(message)}`);
  },
  error: (message: unknown) => {
    if (env.DEV) console.error(`ERROR: ${String(message)}`);
  },
  warn: (message: unknown) => {
    if (env.DEV) console.warn(`WARN: ${String(message)}`);
  },
  debug: (message: unknown) => {
    if (env.DEV) console.debug(`DEBUG: ${String(message)}`);
  },
  log: (message: unknown) => {
    if (env.DEV) console.log(`LOG: ${String(message)}`);
  },
};

export default logger;
