import { redirectToExternal } from '@app/routes/helpers';
import { Button } from 'reactstrap';
import { error } from '../../../assets/images';
import { translate } from '../../../state/utils/helper';
import { Col, Container, H3, ImagePlaceholder, Row } from '../Html';
import Footer from './Footer';

interface ErrorPageProps {
  errorMessage?: string;
  errorImage?: string;
}

const ErrorPage = ({
  errorMessage = translate('errors.error404Message'),
  errorImage = error[404],
}: ErrorPageProps) => {
  return (
    <Container>
      <div className="main-container pt-6">
        <Row>
          <Col className="my-auto">
            <H3 className="text-center font-size-2em my-5">{errorMessage}</H3>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <ImagePlaceholder src={errorImage} className="error404Image" />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              outline
              className="btn-icon btn-3 my-5"
              type="button"
              size="lg"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                redirectToExternal('/', '_self');
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-spaceship" />
              </span>
              <span className="btn-inner--text">{translate('buttonLabels.backToHome')}</span>
            </Button>
          </Col>
        </Row>
      </div>
      <Footer />
    </Container>
  );
};

export default ErrorPage;
