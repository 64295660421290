import type { CardReviewAnswer } from '@app/@types/redux/answer';
import {
  Badge,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledTooltip,
} from '@components/UI/Html';
import { i18nCText } from '@libs/i18n/I18n';
import { translate } from '@state/utils/helper';
import { marked } from 'marked';
import { useState } from 'react';
import { BiTrendingDown } from 'react-icons/bi';
import './FeedbackState.scss';
import Title from './View/Title';

function renderQuestion(question: string, feedback: string | undefined) {
  return (
    <ListGroupItem className="checklist-entry flex-column align-items-start py-2 px-2">
      <h4 className="checklist-title mb-0 pt-2" key={question}>
        <span
          dangerouslySetInnerHTML={{
            __html: i18nCText(question),
          }}
        />
        <h6 className="cardFeedback__aiFeedback">{feedback}</h6>
      </h4>
    </ListGroupItem>
  );
}
interface FeedbackStateProps {
  competencyQuestions: string[];
  skillQuestions: string[];
  preCheckFailed: boolean;
  feedbackText: string | undefined;
  aiRatingFirst: CardReviewAnswer | undefined;
}
const FeedbackState = (props: FeedbackStateProps) => {
  const { competencyQuestions, skillQuestions, preCheckFailed, feedbackText, aiRatingFirst } =
    props;
  const [activeTab, setActiveTab] = useState('Suggestions to Improve');
  return (
    <div className="feedback-container">
      <div className="feedback-tiles-container">
        <div className="feedback-tiles">
          <h3>Feedback</h3>
          <p className="scoring-rationale">SCORING RATIONALE</p>
          <div className="tabs">
            <button
              className={activeTab === 'Suggestions to Improve' ? 'active' : ''}
              onClick={() => {
                setActiveTab('Suggestions to Improve');
              }}
            >
              Suggestions to Improve
            </button>
            <button
              id="disabledTooltip"
              className={preCheckFailed ? 'disabled' : activeTab === 'Key Skills' ? 'active' : ''}
              onClick={() => {
                if (!preCheckFailed) setActiveTab('Key Skills');
              }}
            >
              Key Skills
            </button>
            <button
              id="disabledTooltip"
              className={preCheckFailed ? 'disabled' : activeTab === 'Competencies' ? 'active' : ''}
              onClick={() => {
                if (!preCheckFailed) setActiveTab('Competencies');
              }}
            >
              Competencies
            </button>
            {preCheckFailed && (
              <UncontrolledTooltip delay={0} placement="bottom" target="disabledTooltip">
                Poor Quality
              </UncontrolledTooltip>
            )}
          </div>
        </div>
      </div>

      <div className="tab-content">
        {activeTab === 'Key Skills' && (
          <div>
            <Row>
              <Col>
                <Title>{translate('pageElements.cardFeedback.softSkills')}</Title>
                <ListGroup flush>
                  {skillQuestions.map((question, index) => {
                    const feedback =
                      aiRatingFirst?.feedbacks &&
                      Object.keys(aiRatingFirst.feedbacks).length >= index &&
                      aiRatingFirst.feedbacks[index]?.feedback
                        ? aiRatingFirst.feedbacks[index]?.feedback ?? ''
                        : '';
                    return renderQuestion(question, feedback);
                  })}
                </ListGroup>
              </Col>
            </Row>
          </div>
        )}
        {activeTab === 'Competencies' && (
          <div>
            <Row>
              <Col>
                <Title className="mb-2">{translate('pageElements.cardFeedback.competency')}</Title>
                <ListGroup flush>
                  <div className="">
                    {competencyQuestions.map((question, index) => {
                      const feedback =
                        aiRatingFirst?.competencyFeedbacks &&
                        Object.keys(aiRatingFirst.competencyFeedbacks).length >= index &&
                        aiRatingFirst.competencyFeedbacks[index]?.feedback
                          ? aiRatingFirst.competencyFeedbacks[index]?.feedback ?? ''
                          : '';
                      return renderQuestion(question, feedback);
                    })}
                  </div>
                </ListGroup>
              </Col>
            </Row>
          </div>
        )}
        {activeTab === 'Suggestions to Improve' && (
          <div>
            <Row>
              <Col>
                <Title className="mb-2">{translate('pageElements.cardFeedback.suggestion')}</Title>
                <div className="d-flex align-items-center mb-2">
                  {preCheckFailed ? (
                    <Badge color="danger">
                      <BiTrendingDown className="mr-1" />
                      <span>Poor Quality</span>
                    </Badge>
                  ) : null}
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    __html: preCheckFailed
                      ? translate('pageElements.cardFeedback.poorFeedback')
                      : (marked(feedbackText ?? '') as string),
                  }}
                  className="cardFeedback__feedback"
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};
export default FeedbackState;
