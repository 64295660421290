import logger from '@libs/log';
import React, { useEffect, useRef, useState } from 'react';
import { IoIosPause, IoMdPlay } from 'react-icons/io';
import WaveSurfer from 'wavesurfer.js';
import './AudioPlayer.scss';

interface AudioPlayerProps {
  audioUrl: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl }) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (waveformRef.current) {
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#ddd',
        progressColor: '#00aeff',
        cursorColor: '#0080ff',
        barWidth: 2,
        barHeight: 1,
        height: 50,
      });

      void waveSurferRef.current.load(audioUrl).catch(() => {
        logger.log('error loading audio');
      });

      return () => {
        waveSurferRef.current?.destroy();
      };
    }
  }, [audioUrl]);

  useEffect(() => {
    if (waveSurferRef.current) {
      playing ? void waveSurferRef.current.play() : waveSurferRef.current.pause();
    }
  }, [playing]);

  const handlePlayPause = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.isPlaying()
        ? waveSurferRef.current.pause()
        : void waveSurferRef.current.play();
      setPlaying(!playing);
    }
  };

  return (
    <div className="waveform-container">
      <div>
        <button onClick={handlePlayPause} className="play-button" type="button">
          {playing ? <IoIosPause /> : <IoMdPlay />}
        </button>
      </div>
      <div ref={waveformRef} className="wave" />
    </div>
  );
};

export default AudioPlayer;
